<div class="dialog-content">
    <div class="close-btn">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close (click)="close()">
            X
        </button>
    </div>

    <p>The VIN can often be found on the lower-left corner of the dashboard, in front of the steering wheel. Read it through the windshield on the driver’s side.</p>

    <p>It may also be:</p>
    <ul>
        <li>Inside the driver-side doorjamb</li>
        <li>Driver-side doorpost</li>
        <li>Front of the engine block</li>
        <li>Front of the car frame</li>
        <li>Rear wheel well</li>
        <li>Underneath the spare tire</li>
    </ul>
</div>