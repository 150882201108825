<div  class="dealer-dialog">
    
      <span><h1>Please select Dealership: </h1></span>
      <div  [formGroup]="step">
            <mat-form-field appearance="fill">
                <mat-label>Dealership</mat-label>
                <mat-select formControlName="dealership">
                    <mat-option style="overflow: auto;" *ngFor="let dealership of data.dataKey" value="{{dealership.name}}ID:{{dealership.id}}">
                        {{dealership.name}}
                    </mat-option>
                </mat-select>
                <mat-error
                            *ngIf="step.controls['dealership'].invalid && (step.controls['dealership'].dirty || step.controls['dealership'].touched)">
                                <div *ngIf="step.controls['dealership'].errors?.required">
                                    Dealership is required.
                                </div>
                            </mat-error>
            </mat-form-field>
      </div>
            <div class="text-center mt-4">
                <button mat-raised-button class="btn btn-success btn-lg text-white fw-bold" [disabled]="loggingIn" name="Ok" (click)="Onclick()">OK</button>
            </div>
   
</div>
<style>
    input{
        width: 100%;
    }
    .dealer-dialog{
        width: auto;
    }
</style>
    
