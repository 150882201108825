import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomePageComponent} from "./home-page/home-page.component";
import {LeaseHoldersComponent} from "./lease-holders/lease-holders.component";
import {SchedulerComponent} from "./scheduler/scheduler.component";


export const routes: Routes = [
    {path: '', component: HomePageComponent},
    {path: 'lease-holders', component: LeaseHoldersComponent},
    {path: 'off-lease/schedule-an-appointment', component: SchedulerComponent, data: {userType: 'consumer'}},
    {path: 'dealer/schedule-an-appointment', component: SchedulerComponent, data: {userType: 'dealer'}},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
