import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lease-holders',
  templateUrl: './lease-holders.component.html',
  styleUrls: ['./lease-holders.component.css']
})
export class LeaseHoldersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
