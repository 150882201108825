import { Component } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-root',
    templateUrl: 'resume-scheduling-dialog.html',
    styleUrls: ['./resume-scheduling-dialog.css']
})
export class ResumeSchedulingComponent {
    constructor(public dialogRef : MatDialogRef<ResumeSchedulingComponent>){

    }
    close(): void {
        this.dialogRef.close();
    }
}