import {IfInvalid} from "luxon/src/_util";

export class GetSaTimeSlotsRequest {
    LOB: string = 'OL';
    AssignmentId: string | number = 0;
    Street: string = '';
    City: string = '';
    State: string = '';
    PostCode: number = 0;
    WorkType: string = '';
    FromDate: string | IfInvalid<null> = '';
    ToDate: string | IfInvalid<null> = '';
    TimeProfile: string = '';
}
