export class CreateOlWorkItemRequest {
    AccountNumber: string|number = 0;
    AcvOrgID: string|number = 0;
    Address_1: string = '';
    Address_2: string = '';
    AssgnInspType: null = null;
    City: string = '';
    Comments: string = '';
    Contact_Home_Phone: string = '';
    Contact_Name: string = '';
    Contact_Work_Phone: null = null;
    Customer_Code: null = null;
    ExternalColor: null = null;
    InspectionType: null = null;
    LicenseNumber: null = null;
    Maturity_Date: string = '';
    SiteName: string = 'Turn In Location';
    State: string = '';
    TurnInSite_Type: string = 'H';
    Vehicle_VIN: string = '';
    Vehicle_Year: string | number = '';
    Zip: string | number = '';
}
