import { Component } from "@angular/core";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {Router} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: 'final-dialog.html'
})
export class FinalThankyouDialog {
    constructor(public dialogRef: MatDialogRef<FinalThankyouDialog>,
        private router : Router,
        public dialog: MatDialog,){}

    close(): void {
        this.dialogRef.close();
    }
    click()
    {
        this.router.navigate(['dealer', 'schedule-an-appointment']);
        this.dialog.closeAll();
    }
}