<div class="close-btn">
    <button title="Close Availability Request Form" mat-icon-button aria-label="close dialog" mat-dialog-close
            (click)="closeDialog()">X
    </button>
</div>
<div class="arf-dialog" text-align="center">
    <h1 mat-dialog-title text-align="center" width="590px">
        &nbsp;&nbsp;&nbsp;Availability Request Form
    </h1>
</div>
<div class="container" [formGroup]="step">
    <div class="row">
        <div class="col-md-12">
            &nbsp;Your VIN:&nbsp;&nbsp;<span>{{this.arfRequestData.VIN}}</span>
        </div>
        <br/><br/>
        <div class="col-md-12">
            <mat-form-field class="">
                <mat-label>Email</mat-label>
                <input readonly="false" matInput placeholder="ljohnsmith@email.com" formControlName="arfemail"/>
                <mat-error
                    *ngIf="step.controls['arfemail'].invalid && (step.controls['arfemail'].dirty || step.controls['arfemail'].touched)">

                    <div *ngIf="step.controls['arfemail'].errors?.required">
                        Email is required.
                    </div>
                    <div
                        *ngIf="step.controls['arfemail'].errors?.arfemail || step.controls['arfemail'].errors?.pattern">
                        Please enter a valid email address.
                    </div>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Additional Information</mat-label>
                <textarea matInput placeholder="Additional Information" formControlName="arfAdditionalInfo"
                          cdkTextareaAutosize cdkAutosizeMinRows="6"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="submittedArf && submitErrorId === 0">
        <div class="col-12 mt-4">
            <span class="text-success message-bold">A request for an inspection date has been submitted successfully.</span>
            <br><span class="text-success message-bolder">We will promptly address your request and provide a response within 48 hours.</span>
            <br><span class="text-success message-bolder">Thank you.</span>
        </div>
    </div>
    <div class="row" *ngIf="submitErrorId === 1">
        <div class="col-12 mt-4">
            <span class="text-danger message-bold">There was an issue when creating your request for a date.  Please reach out to 1-800-340-4089<br>for assistance.</span>
        </div>
    </div>
    <div class="row" *ngIf="submitErrorId === 2">
        <div class="col-12 mt-4">
            <span class="text-danger message-bold">A request for an inspection date has already been submitted.  Please reach out to<br>1-800-340-4080 with any questions.</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mt-4">
            <button class="btn btn-danger btn-lg text-white fw-bold mr-2" (click)="closeDialog()">
                {{!submittedArf ? 'Cancel' : 'Close'}}
            </button>
            &nbsp;
            <button *ngIf="!submittedArf" class="btn btn-success btn-lg text-white fw-bold" (click)="submitArf()">
                <span class="spinner-border spinner-border-sm text-white align-baseline" role="status"
                      aria-hidden="true" *ngIf="submittingArf"></span>
                {{ !submittingArf ? 'Submit Availability Request' : 'Submitting...'}}
            </button>
        </div>
    </div>
</div>
<style>
    .arf-dialog {
        overflow-y: auto;
        background-color: white;
        width: 600px;
    }
</style>
