export class CreateArfRequest {
    // class to create ARF (availability request form) request
    Appointment_CreateUser: string = 'CSchedulerUser'; // it is default value when the user is logged in
    AppointmentType: string | null = '';
    Assignment_Date: string = '';  // It is not know when the ARF is requested
    AssignmentId: string = ''; // this.assignmentId property on the scheduler
    City: string = ''; // on the form: this.step2.controls['city'].value
    Color: string = '';
    Country: string = 'US';
    CustomerName: string = '';
    Exist: boolean = false; // default value is false
    Forced: boolean = false; // default value is false
    LesseeName: string = '';
    LOB: string = 'OL';
    Make: string = ''; // this.step1.controls['make'].value
    MaturityDate: string = ''; //this.maturityDate
    Model: string = ''; //this.step1.controls['model'].value
    Series: string = '';
    State: string = '';
    Street: string = '';
    VIN: string = ''; // form this.step1.controls['vin'].value
    WorkType: string = '';
    Zipcode: string = '';
    IsARF: string = '';
    ARFNotes: string = ''; // dialog window form field
    ContactEmail: string = ''; // dialog window form field
    UserId: string | number = 0;
    CountryCode: string = '';
    Language: string = 'EN';
}
