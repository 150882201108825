import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";
import {GetAsiOrgIdRequest} from "../../models/get-asi-org-id-request";
import {GetValidWorkItemRequest} from "../../models/get-valid-work-item-request";
import {CancelSaRequest} from "../../models/cancel-sa-request";
import {GetAsiOrgIdCodeNameRequest} from "../../models/get-asi-org-id-code-name-request";
import {GetProfilesForWorkTypeRequest} from "../../models/get-profiles-for-work-type-request";
import {GetProfilesForWorkTypeResponse} from "../../models/get-profiles-for-work-type-response";
import {GetSaTimeSlotsRequest} from "../../models/get-sa-time-slots-request";
import {GetWorkItemSaRequest} from "../../models/get-work-item-sa-request";
import {GetWorkTypesRequest} from "../../models/get-work-types-request";
import {ReserveSaRequest} from "../../models/reserve-sa-request";
import {SaveSawEmailRequest} from "../../models/save-saw-email-request";
import {SearchWorkItemsRequest} from "../../models/search-work-items-request";
import {CreateOlWorkItemRequest} from "../../models/create-ol-work-item-request";
import {GetCitiesRequest} from 'src/models/get-cities-request';
import {SaveAssistanceSupportRequest} from "../../models/save-assistance-support-request";
import {CreateArfRequest} from "../../models/create-arf-request";
import {Observable, of} from "rxjs";
import {CreateArfResponse} from "../../models/create-arf-response";

@Injectable({
    providedIn: 'root'
})
export class AcvService extends ApiService {
    constructor(http: HttpClient) {
        super(http);
    }

    CancelSA(data: CancelSaRequest) {
        return this.get(this.api_url + '/CancelSA', data);
    }

    CreateOLWorkItem(data: CreateOlWorkItemRequest) {
        return this.post(this.api_url + '/CreateOLWorkItem', data);
    }

    GetASIOrgID(data: GetAsiOrgIdRequest) {
        return this.get(this.api_url + '/GetASIOrgID', data);
    }

    GetAsiOrgIdCodeName(data: GetAsiOrgIdCodeNameRequest) {
        return this.get(this.api_url + '/GetAsiOrgIdCodeName', data);
    }

    GetCities(data: GetCitiesRequest) {
        return this.get(this.api_url + '/GetCities', data);
    }

    GetProfilesForWorkType(data: GetProfilesForWorkTypeRequest) {
        return this.get(this.api_url + '/GetProfilesForWorkType', data);
    }

    GetSATimeSlots(data: GetSaTimeSlotsRequest) {
        return this.get(this.api_url + '/GetSATimeSlots', data);
    }

    GetSearchBy(data: {}) {
        return this.get(this.api_url + '/GetSearchBy', data);
    }

    GetValidWorkItem(data: GetValidWorkItemRequest) {
        return this.post(this.api_url + '/GetValidWorkItem', data);
    }

    GetWorkItemSA(data: GetWorkItemSaRequest) {
        return this.get(this.api_url + '/GetWorkItemSA', data);
    }

    GetWorkSAStatus(data: {}) {
        return this.get(this.api_url + '/GetWorkSAStatus', data);
    }

    GetWorkTypes(data: GetWorkTypesRequest) {
        return this.get(this.api_url + '/GetWorkTypes', data);
    }

    GetDealerStatus(data: number[]) {
        return this.post(this.api_url + '/GetDealerStatus', data)
    }

    LogEventError(data: {}) {
        return this.post(this.api_url + '/LogEventError', data);
    }

    ReserveSA(data: ReserveSaRequest) {
        return this.post(this.api_url + '/ReserveSA', data);
    }

    SaveSAwEmail(data: SaveSawEmailRequest) {
        return this.post(this.api_url + '/SaveSAwEmail', data);
    }

    SearchWorkItems(data: SearchWorkItemsRequest) {
        return this.post(this.api_url + '/SearchWorkItems', data);
    }

    SaveAppointmentAssistanceDetails(data: SaveSawEmailRequest) {
        return this.post(this.api_url + '/SaveSA', data);
    }

    SaveARFData(data: CreateArfRequest): Observable<CreateArfResponse> {
        return this.post(this.api_url + '/SaveARFData', data);
     }

}
