<div class="menu-container container-fluid">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light menu-bar">
            <a class="navbar-brand" href="/"><h1 class="logo">True360</h1></a>

            <button [matMenuTriggerFor]="belowMenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#true30Menu" aria-controls="true30Menu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <mat-menu #belowMenu="matMenu" yPosition="below">
                <button mat-menu-item routerLink="/lease-holders">Off-lease Inspection</button>
                <button mat-menu-item (click)="loginForm()">Appraisal Inspection</button>
                <button mat-menu-item (click)="aboutTrue360()">About True360</button>
            </mat-menu>
            <div class="collapse navbar-collapse" id="true30Menu">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link"   routerLink="/lease-holders">Off-lease Inspection</a>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="loginForm()">Appraisal Inspection</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link" (click)="aboutTrue360()">About True360</span>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>


<router-outlet></router-outlet>
