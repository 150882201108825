import {IfInvalid} from "luxon/src/_util";

export class SaveSawEmailRequest {
    AccountNumber: string = '';
    AcvOrgId: string | number = 0;
    AdditionalAddress: null = null;
    AddressLine1: string = '';
    AddressLine2: string = '';
    Appointment_Current_Status: string = 'Scheduled';
    Appointment_InspectionContact: string = '';
    AppointmentDate: string | IfInvalid<null> = '';
    AppointmentTime: string = '';
    AppointmentType: string = '-C-';
    assignmentId: string | number = 0;
    CEMEmail: string|null = null;
    CEMIVRNumber: string|null = null;
    CEMSMSNumber: string|null = null;
    CEMUpdateUser: string = '';
    City: string = '';
    confirmationNumber: string = '';
    ContactEmail: string = '';
    ContactMobileNumber: string = '';
    ContactName: string = '';
    ContactPhoneNumber: string = '';
    Country: string = 'US';
    countrycode: string = 'US';
    CustomerEmail: string = '';
    CustomerId: string | number = '';
    DealerCode: string | null = null;
    DealerName: string = '';
    DispatchInstructions: string = '';
    District: string = '';
    DrivingDirections: string = '';
    Duration: number = 0;
    EmailConfirmationType: string = 'H';
    ExteriorColor: string|null = null;
    InspectionPlace: string = 'Inspection Location';
    InspectorId: number = 0;
    InspectorInstructions: string|null = null;
    isCEM: boolean = false;
    language: string = 'EN';
    Latitude: number = 0;
    LesseeName: string|null = null;
    LOB: string = 'OL';
    Longitude: number = 0;
    Make_ID: number = 0;
    Maturity_Date: string = 'OL';
    Model_ID: number = 0;
    SelectedGrade: null = null;
    Organization_ID: string | number = '';
    partyId: null = null;
    PlaceName: string = 'Inspection Location';
    PostalCode: string = '';
    Region: string = '';
    ReSchedule_Flag: number = 0;
    SCSComments: null = null;
    sendByCEMEmail: boolean = false;
    sendByCEMIVR: boolean = false;
    sendByCEMSMS: boolean = false;
    SendEmail: boolean = true;
    Series_ID: number = 0;
    SiteType: string = 'H';
    SMSAlertPhoneNo: null = null;
    SpokeWith: string = '';
    State: string = '';
    TaskType: string = '';
    MaxGrade: number = 100;
    UpdateAdditional_Info: number = 0;
    userid: number = 0;
    Vehicle_VIN: string = '';
    WorkUnits: number = 1;
    Year: number = 1;
}
