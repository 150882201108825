<div class="outstanding">
    <span style="text-align: center;">
        <p>
            <b>Outstanding! Thank you for taking good care of your vehicle. You are pre-qualified to return your vehicle without a pre-term inspection. </b>
        </p>
    </span>
    <div [formGroup] = "step03">
        <div>
            <mat-label>Did you want to proceed with scheduling an inspection anyway?  :* </mat-label>
        <mat-radio-group (change)="onchange()" style="padding:10px" aria-label="Select an option" formControlName="proceed" aria-required="true">
            <br><mat-radio-button style="padding:10px" value="1">Yes</mat-radio-button><br>
          <mat-radio-button style="padding:10px" value="2">No</mat-radio-button>
        </mat-radio-group>
        </div>
    </div>
    <div *ngIf="selecterror">
        <mat-error>
            <div>Please select an option. * Required</div>
        </mat-error>
    </div>
    <div class="text-center mt-4">
        <button mat-raised-button class="btn btn-success btn-lg text-white fw-bold" [disabled] = "loading" (click)="Submit()" name="Submit">Submit</button>
      </div>

</div>