import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {google, ics, outlook} from "calendar-link";
import {Observable, of, throwError} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {WeekDay} from '../week-day';
import {DateTime} from "luxon";
import {ConfirmCancellationDialog} from "../dialogs/confirm-cancellation-dialog/confirm-cancellation-dialog.component";
import {CemUpdateDialogComponent} from '../dialogs/cem-update-dialog/cem-update-dialog.component';
import {environment} from "../../environments/environment";
import {CookieService} from "ngx-cookie-service";
import {AcvService} from "../services/acv.service";
import {GetAsiOrgIdRequest} from "../../models/get-asi-org-id-request";
import {SearchWorkItemsRequest} from "../../models/search-work-items-request";
import {SearchWorkItemsResponse} from "../../models/search-work-items-response";
import {GetAsiOrgIdResponse} from "../../models/get-asi-org-id-response";
import {GetWorkItemSaRequest} from "../../models/get-work-item-sa-request";
import {GetWorkItemSaResponse} from "../../models/get-work-item-sa-response";
import {GetWorkTypesRequest} from "../../models/get-work-types-request";
import {GetWorkTypesResponse} from "../../models/get-work-types-response";
import {GetProfilesForWorkTypeRequest} from "../../models/get-profiles-for-work-type-request";
import {GetProfilesForWorkTypeResponse} from "../../models/get-profiles-for-work-type-response";
import {GetSaTimeSlotsRequest} from "../../models/get-sa-time-slots-request";
import {GetSATimeSlotsResponse} from "../../models/get-satime-slots-response";
import {GetValidWorkItemRequest} from "../../models/get-valid-work-item-request";
import {GetValidWorkItemResponse} from "../../models/get-valid-work-item-response";
import {CancelSaRequest} from "../../models/cancel-sa-request";
import {CancelSaResponse} from "../../models/cancel-sa-response";
import {CreateOlWorkItemRequest} from "../../models/create-ol-work-item-request";
import {CreateOlWorkItemResponse} from "../../models/create-ol-work-item-response";
import {ReserveSaRequest} from "../../models/reserve-sa-request";
import {ReserveSaResponse} from "../../models/reserve-sa-response";
import {SaveSawEmailRequest} from "../../models/save-saw-email-request";
import {SaveSawEmailResponse} from "../../models/save-saw-email-response";
import {AboutVinDialogComponent} from "../dialogs/about-vin-dialog/about-vin-dialog.component";
import {GetCitiesRequest} from 'src/models/get-cities-request';
import {GetCitiesResponse} from 'src/models/get-cities-response';
import {DateWarningDialogComponent} from '../dialogs/date-warning-dialog/date-warning-dialog.component';
import {HondaSurveyDialogComponent} from '../dialogs/Honda-survey-dialog/hond-survey-dialog.component';
import {AcuraSurveyDialogComponent} from '../dialogs/Acura-survey-dialog/acura-survey-dialog.component';
import {ACVAnywhere_FAQ_Dialog_Component} from '../dialogs/FAQ_ACVAnywhere_dialog/FAQ_dialog.component';
import {routes} from '../app-routing.module'
import {GetDealerStatusResponse} from "../../models/get-dealer-status-response";
import {Helper} from "../../helpers/helper";
import {IfInvalid} from "luxon/src/_util";
import {TimeSlot} from "../../models/time-slot";
import {ArfDialogComponent} from "../dialogs/arf-dialog/arf-dialog.component";
import {CreateArfRequest} from "../../models/create-arf-request";


const weeksNumberToCacheTimeSlots: number = 4;

// interface to keep steps jumps
interface IStepsJump {
    CurrStep: number; // step we navigate from
    Step: number; // next step we navigate to
}

class StepsJumpsTables {
    static ConsumerNonFlorida: IStepsJump[] = [  // case "consumer", "non-Florida"
        {CurrStep: 1, Step: 2},
        {CurrStep: 2, Step: 4},
        {CurrStep: 4, Step: 5},
        {CurrStep: 5, Step: 7},
        {CurrStep: 7, Step: -1} // next step is defined programmatically
    ];
    static DealerNonFlorida: IStepsJump[] = [ // case "dealer", "non-Florida"
        {CurrStep: 1, Step: 2},
        {CurrStep: 2, Step: 3},
        {CurrStep: 3, Step: 4},
        {CurrStep: 4, Step: 5},
        {CurrStep: 5, Step: 7},
        {CurrStep: 7, Step: -1} // next step is defined programmatically
    ];
    static ConsumerFlorida: IStepsJump[] = [  // case "consumer", "Florida"
        {CurrStep: 1, Step: 2},
        {CurrStep: 2, Step: 4},
        {CurrStep: 4, Step: 5},
        {CurrStep: 5, Step: 6},
        {CurrStep: 6, Step: 7},
        {CurrStep: 7, Step: -1} // next step is defined programmatically
    ];
    static DealerFlorida: IStepsJump[] = [  // case "dealer", "Florida"
        {CurrStep: 1, Step: 2},
        {CurrStep: 2, Step: 3},
        {CurrStep: 3, Step: 4},
        {CurrStep: 4, Step: 5},
        {CurrStep: 5, Step: 6},
        {CurrStep: 6, Step: 7},
        {CurrStep: 7, Step: -1} // next step is defined programmatically
    ];

    static StepsJumpTableGet(userType: "consumer" | "dealer", isFlorida: boolean): IStepsJump[] {
        switch (userType) {
            case "consumer": {
                switch (isFlorida) {
                    case true:
                        return this.ConsumerFlorida;
                    case false:
                        return this.ConsumerNonFlorida;
                }
            }
            case "dealer": {
                switch (isFlorida) {
                    case true:
                        return this.DealerFlorida;
                    case false:
                        return this.DealerNonFlorida;
                }
            }
        }
    }

    static StepperIndexByStepGet(userType: "consumer" | "dealer", isFlorida: boolean, step: number): number {
        let stepsJumpTable = this.StepsJumpTableGet(userType, isFlorida);
        return stepsJumpTable.findIndex((elem) => elem.CurrStep === step);
    }
}


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Accept': '*/*',
        // 'Connection': 'keep-alive',
    })
};


@Component({
    selector: 'app-scheduler',
    templateUrl: './scheduler.component.html',
    styleUrls: ['./scheduler.component.css']
})
export class SchedulerComponent implements OnInit, OnDestroy {
    [x: string]: any;

    apiUrl = environment.api_url;
    currentStep = 1;
    vinNotFound = false;
    maturityDateIsOver: boolean = false;
    floatLabelControl = new UntypedFormControl('auto');
    title = "Schedule an Appointment";
    name = "";
    currentDates: Array<WeekDay> = [];
    navigator = 0;
    userType: "consumer" | "dealer" = "consumer";
    selectedtime = false;
    assignmentId: string | number = 0;
    customerId: string | number = 0;
    series: string = '';
    appointmentType: string = '';
    color: string = '';
    customer_name: string = '';
    workType = "";
    workTypeProfile = "";
    cancelling = false;
    loading = false;
    editable = false;
    completed = false;
    timeSlots: any[] = new Array<any>;

    firstDate: DateTime = DateTime.now().setZone('America/New_York').startOf('week');
    lastDate: DateTime = this.firstDate.endOf('week');
    firstDates: DateTime = DateTime.now().setZone('America/New_York').startOf('week');
    selectedWeekDay: WeekDay = {
        'name': "",
        'date': DateTime.now(),
        'slot1': {'status': "", 'start_time': "", 'end_time': ""},
        'slot2': {'status': "", 'start_time': "", 'end_time': ""}
    };
    currentMonth: string | IfInvalid<null> = "";
    currentYear = 0;
    maturityDate = "";
    acvOrgId: string | number = 0;
    dealerId: string | number = 0;
    userId: string | number = 0;
    accountId: string | number = "";
    assignmentDate: string = "";
    acvOrgName = "";
    acvOrgCode: string | number = 0;
    confirmationNumber = "";
    selectedTimePeriod = "";
    loadingSchedule = false;
    firstCalendarLoad = true;
    errorMessage = "";
    googleCalLink = "";
    iCalLink = "";
    outlookCalLink = "";
    step1Error = "";
    step2Error = "";
    step3Error = "";
    step4Error = "";
    step5Error = "";
    step7Error = false;
    states = [
        {abbreviation: "AL", state: "Alabama"},
        {abbreviation: "AK", state: "Alaska"},
        {abbreviation: "AZ", state: "Arizona"},
        {abbreviation: "AR", state: "Arkansas"},
        {abbreviation: "CA", state: "California"},
        {abbreviation: "CO", state: "Colorado"},
        {abbreviation: "CT", state: "Connecticut"},
        {abbreviation: "DE", state: "Delaware"},
        {abbreviation: "DC", state: "District of Columbia"},
        {abbreviation: "FL", state: "Florida"},
        {abbreviation: "GA", state: "Georgia"},
        {abbreviation: "HI", state: "Hawaii"},
        {abbreviation: "ID", state: "Idaho"},
        {abbreviation: "IL", state: "Illinois"},
        {abbreviation: "IN", state: "Indiana"},
        {abbreviation: "IA", state: "Iowa"},
        {abbreviation: "KS", state: "Kansas"},
        {abbreviation: "KY", state: "Kentucky"},
        {abbreviation: "LA", state: "Louisiana"},
        {abbreviation: "ME", state: "Maine"},
        {abbreviation: "MD", state: "Maryland"},
        {abbreviation: "MA", state: "Massachusetts"},
        {abbreviation: "MI", state: "Michigan"},
        {abbreviation: "MN", state: "Minnesota"},
        {abbreviation: "MS", state: "Mississippi"},
        {abbreviation: "MO", state: "Missouri"},
        {abbreviation: "MT", state: "Montana"},
        {abbreviation: "NE", state: "Nebraska"},
        {abbreviation: "NV", state: "Nevada"},
        {abbreviation: "NH", state: "New Hampshire"},
        {abbreviation: "NJ", state: "New Jersey"},
        {abbreviation: "NM", state: "New Mexico"},
        {abbreviation: "NY", state: "New York"},
        {abbreviation: "NC", state: "North Carolina"},
        {abbreviation: "ND", state: "North Dakota"},
        {abbreviation: "OH", state: "Ohio"},
        {abbreviation: "OK", state: "Oklahoma"},
        {abbreviation: "OR", state: "Oregon"},
        {abbreviation: "PA", state: "Pennsylvania"},
        {abbreviation: "RI", state: "Rhode Island"},
        {abbreviation: "SC", state: "South Carolina"},
        {abbreviation: "SD", state: "South Dakota"},
        {abbreviation: "TN", state: "Tennessee"},
        {abbreviation: "TX", state: "Texas"},
        {abbreviation: "UT", state: "Utah"},
        {abbreviation: "VT", state: "Vermont"},
        {abbreviation: "VA", state: "Virginia"},
        {abbreviation: "WA", state: "Washington"},
        {abbreviation: "WV", state: "West Virginia"},
        {abbreviation: "WI", state: "Wisconsin"},
        {abbreviation: "WY", state: "Wyoming"}
    ];
    step1: UntypedFormGroup;
    step2: UntypedFormGroup;
    step3: UntypedFormGroup;
    step4: UntypedFormGroup;
    step5: UntypedFormGroup;
    step6: UntypedFormGroup;  // Appointment assistance
    step7: UntypedFormGroup;  // Confirmation (it was step 6)
    navigatorDate = DateTime.now().setZone('America/New_York').startOf('week');
    @ViewChild('stepper') stepper: any;
    state: string = "";
    Cities: { PostalCity: string; PostalState: string; PostalCode: number; region: string; }[] = [];
    today: DateTime = DateTime.now();
    startmaturitydate = new Date();
    ccmaturity = new Date();
    validstate: boolean = false;
    W_N_maturity: DateTime = DateTime.now();
    W_N_startmaturity: DateTime = DateTime.now();

    // Appointment assistance variables
    appointAssistanceLinkVisibleForFlorida: boolean = false; // to be set to false if state is not Florida (testing requirement)
    assistEnrolledInAppAssistVal: boolean = false;
    assistAgreeToSmsVal: boolean = false;
    assistFirstNameVal: string = '';
    assistLastNameVal: string = '';
    assistNameOfPersonVal: string = '';
    assistMobileNumber: string = '';
    assistHideTextFields: boolean = true;
    assistLoading = false
    assistAssignmentId: string | number = 0;
    assistUserId: number = 2563;  // this constant user id is set in accordance with Aaron's advice (for SaveSA request)
    listOfDealersWhoCanNotSendSMS: string[] = [];
    listOfDealersWhoCanSendSMS: string[] = [];
    userCanSendSms: boolean = true;

    // variables, related to task "ACV Anywhere Same VIN multiple Dealers"
    assignedAppointmentDate: string = "";

    // property to manage Appointment Assistance sub-statuses
    // (additional "Confirm")
    // 1 - not confirmed yet
    // 2 - confirmed
    appAssistSubStep: number | null = 1;

    // variables to manage time slots caching
    changeWeekCounterLimit: number = 3; // "next" week limiter to avoid endless requests (for "automatic" week change)
    changeWeekCounter: number = 0;
    timeSlotsCacheHadBeenReceived: boolean = false;
    timeSlotsHadBeenReceivedFirstTime: boolean = true;
    epochStart: DateTime = DateTime.fromMillis(0);  // "epoch start", something like initial date/timechange to avoid nullable DateTime
    currentFromDateForCache: DateTime = DateTime.fromMillis(0); // "epoch start"
    currentToDateForCache: DateTime = DateTime.fromMillis(0); // "epoch start"

    isArfSubmittedForCurrentVin: boolean = false; // true if Availability Request Form is submitted for current VIN code already

    constructor(
        public dialog: MatDialog,
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private _formBuilder: UntypedFormBuilder,
        private cookieService: CookieService,
        private acvService: AcvService,
    ) {
        this.firstDate = this.firstDate.plus({days: 1});
        this.step1 = this._formBuilder.group({
            vin: ['', [Validators.required, Validators.minLength(17), Validators.maxLength(17)]],
            accountNumber: [''],
            make: [''],
            model: [''],
            year: [''],
        });
        this.step2 = this._formBuilder.group({
            streetAddress1: ['', Validators.required],
            streetAddress2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern("^[0-9]*$")]],
            additionalInfo: [''],
            drivingDirections: ['', Validators.required],
        });
        this.step3 = this._formBuilder.group({
            ownerName: [''],
            ownerPhone: [''],
        });
        this.step4 = this._formBuilder.group({});
        this.step5 = this._formBuilder.group({
            name: ['', Validators.required],
            mobileNumber: ['', Validators.required],
            mobileNumberConfirm: [''],
            email: ['', [Validators.required, Validators.email, Validators.pattern("^\\S+@\\S+\\.\\S+$")]],
        }, {
            validators: NumberMustMatch('mobileNumber', 'mobileNumberConfirm')
        });
        this.step6 = this._formBuilder.group({
                assistFirstName: ['', Validators.required],
                assistLastName: ['', Validators.required],
                assistNameOfPerson: ['', Validators.required],
                assistMobileNumber: ['', Validators.required],
                assistConfirmMobileNumber: [''],
                enrolledInAppAssist: [''],
                agreeToSms: [''],
            },
            {
                validators: NumberMustMatch('assistMobileNumber', 'assistConfirmMobileNumber')
            }
        );
        this.step7 = this._formBuilder.group({});
    }

    ngOnInit() {
        this.userType = this.route.snapshot.data.userType;
        if (this.userType === "consumer") {
            this.step1.controls['accountNumber'].setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
            this.step1.controls['accountNumber'].updateValueAndValidity();
            this.userCanSendSms = true;  // consumer can always see "Appointment Assistance" and send SMS for whole countru
        }

        if (this.userType === "dealer") {
            this.dealerId = this.cookieService.get('dealerId')
            this.userId = this.cookieService.get('userId');
            if (!this.dealerId || !this.userId) {
                this.router.navigate(['/']);
                return;
            }

            // this.listOfDealersWhoCanNotSendSMS = Helper.DealersListDeserialize(
            //     this.cookieService.get('listOfDealersWhoCanNotSendSMS'))


            this.listOfDealersWhoCanSendSMS = Helper.DealersListDeserialize(
                this.cookieService.get('listOfDealersWhoCanSendSMS'))

            this.userCanSendSms = this.listOfDealersWhoCanSendSMS.indexOf(this.dealerId) >= 0
            //this.listOfDealersWhoCanNotSendSMS.indexOf(this.dealerId) < 0;
            this.getOrgId();

            this.step1.controls['make'].setValidators([Validators.required]);
            this.step1.controls['model'].setValidators([Validators.required]);
            this.step1.controls['year'].setValidators([Validators.required]);

            this.step1.controls['make'].updateValueAndValidity();
            this.step1.controls['model'].updateValueAndValidity();
            this.step1.controls['year'].updateValueAndValidity();

            this.step3.controls['ownerName'].setValidators([Validators.required]);
            this.step3.controls['ownerPhone'].setValidators([Validators.required]);

            this.step3.controls['ownerName'].updateValueAndValidity();
            this.step3.controls['ownerPhone'].updateValueAndValidity();
        }

        if (this.userCanSendSms) {
            this.appointAssistanceLinkVisibleForFlorida = true; // sms sending step is visible for a whole country
        }
    }

    ngOnDestroy() {
    }

    openDialog() {
        const dialogRef = this.dialog.open(AboutVinDialogComponent, {width: '600px'});

        dialogRef.afterClosed().subscribe(result => {
            //console.log(`Dialog result: ${result}`);
        });
    }

    closeDialog() {

    }

    // auxiliary methods to process time slots.
    sortTimeSlots(): void {
        // add new property (to sort on it)
        // @ts-ignore
        this.timeSlots.map((obj) => {
            // @ts-ignore
            if (obj.startDateTime === undefined) {
                // @ts-ignore
                obj["startDateTime"] = new Date(obj.StartTime);
            }
            return obj;
        });
        // sort on new (Date) property (to avoid sorting by string values)
        this.timeSlots.sort((a, b) => {
            // @ts-ignore
            return a.startDateTime.getTime() - b.startDateTime.getTime();
        });
    }

    keepUniqueTimeSlotsOnly(): void {
        // sometimes the back end returns duplicates (time slots for future weeks along with requested for this week)
        // it is better to remove duplicates
        //https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
        // part "For those who want to return object with all properties unique by key"
        const key: string = "StartTime";
        this.timeSlots = [...new Map(this.timeSlots.map(item => [item[key], item])).values()];
    }


    removeTimeSlotsWithWrongTimeFrames(): void {
        this.timeSlots = this.timeSlots.filter((obj) => {
            return obj.TimeFrame.toLowerCase() !== "7:00 am to 5:00 pm";
        });
    }

    isDateLessThanToday(startDateTime: Date): boolean {
        let startDate = DateTime.fromJSDate(startDateTime);
        return startDate < this.today
    }

    removePastTimeSlots(): void {
        this.timeSlots = this.timeSlots.filter((obj) => {
            // @ts-ignore
            return !this.isDateLessThanToday(obj.startDateTime);
        });
    }


    isDateToday(startDateTime: Date) {
        let startDate = DateTime.fromJSDate(startDateTime);
        return (
            startDate.day == this.today.day &&
            startDate.month == this.today.month &&
            startDate.year == this.today.year
        );
    }

    removeTodayFromTimeSlots(): void {
        this.timeSlots = this.timeSlots.filter((obj) => {
            // @ts-ignore
            return !this.isDateToday(obj.startDateTime);
        });
    }

    noTimeSlotsInsideCurrentWeek(appointmentTimeSlots: Array<object>): boolean {
        let currentWeekBegin: DateTime = this.firstDate;
        let currentWeekEnd: DateTime = currentWeekBegin.endOf('week');
        for (let i = 0; i < appointmentTimeSlots.length; i++) {
            // @ts-ignore
            let tsDate = DateTime.fromJSDate(new Date(appointmentTimeSlots[i].Date))
                .setZone('America/New_York')
                .plus({hours: 12})
            if (tsDate >= currentWeekBegin && tsDate <= currentWeekEnd) {
                return false; // at least one timeslot is available within current week
            }
        }
        return true;
    }

    initVariablesToManageTimeSlotsCache(): void {
        this.timeSlots = new Array<any>;
        this.changeWeekCounter = 0;
        this.timeSlotsCacheHadBeenReceived = false;
        this.timeSlotsHadBeenReceivedFirstTime = true;
        this.currentFromDateForCache = DateTime.fromMillis(0); // "epoch start"
        this.currentToDateForCache = DateTime.fromMillis(0); // "epoch start"
    }

    navigate(step = 0, override = false): void {
        let validated = true;
        this.errorMessage = "";
        if (this.currentStep === 1 && this.userType === "dealer" && override === true) {
            this.step1.markAllAsTouched();
            if (this.step1.invalid) {
                return;
            }
            this.loading = true;
            this.firstCalendarLoad = true; // if dealer returned back to this point, the new assignment will
            // be created and calendar will be reloaded
            this.searchWorkItems();
            validated = false;
        }

        if (this.currentStep === 2 && step === 3) {
            this.step2.markAllAsTouched();

            if (this.step2.invalid) {
                validated = false;
            }
            //this.firstCalendarLoad = true; // if dealer returned back to this point, the new assignment will
            // be created and calendar will be reloaded
        }

        if (this.currentStep === 5 && step === 6) {
            this.step5.markAllAsTouched();
            if (this.step5.invalid) {
                validated = false;
            }
        }
        if (this.currentStep === 5 && step === 7) {
            this.step5.markAllAsTouched();
            if (this.step5.invalid) {
                validated = false;
            }
        }

        if (this.currentStep === 6 && step === 7) {
            if (this.assistHideTextFields) {
                validated = true;  // do not validate text fields if user does not want appointment assistance
            } else {
                this.step6.markAllAsTouched();
                if (this.step6.invalid) {
                    validated = false;
                }
            }
        }


        if (validated) {
            this.completeStep();
            if (this.currentStep < step || override) {
                if (this.userType === 'consumer') {
                    if (step === 1 && this.vinNotFound) {
                        this.title = "Unable to Find Record";
                    } else if (step < 5) {
                        this.title = "Schedule an Appointment";
                    } else if ((step === 5 && !this.appointAssistanceLinkVisibleForFlorida) ||
                        (step === 6 && this.appointAssistanceLinkVisibleForFlorida)) {
                        this.title = "Confirm Appointment";
                        if (override) {
                            if (!this.selectedWeekDay.name) {
                                this.errorMessage = "You must select a day to schedule";
                            }
                        }

                        let appointmentTime = "";

                        if (this.selectedWeekDay.slot1.status === 'selected') {
                            appointmentTime = "8:00 AM";
                        } else if (this.selectedWeekDay.slot2.status === 'selected') {
                            appointmentTime = "12:30 PM";
                        }

                        this.selectedTimePeriod = appointmentTime + " " + this.selectedWeekDay.date.weekdayLong + " " + this.selectedWeekDay.date.toLocaleString(DateTime.DATE_FULL);
                    } else if (step === 8) {
                        this.title = "Thank You";
                    } else if (step === 9) {
                        this.title = "Appointment Status";
                    }
                } else if (this.userType === 'dealer') {
                    if (step === 1 && this.vinNotFound) {
                        this.title = "Unable to Find Record";
                    } else if (step === 2 && this.step1Error) {
                        // multiple appointments or assignments
                        this.errorMessage = "Error."
                        this.loading = false;
                    } else if (step < 5) {
                        this.title = "Schedule an Appointment";
                    } else if ((step === 5 && !this.appointAssistanceLinkVisibleForFlorida) ||
                        (step === 6 && this.appointAssistanceLinkVisibleForFlorida)) {
                        this.title = "Confirm Appointment";
                        let appointmentTime = "";

                        if (this.selectedWeekDay.slot1.status === 'selected') {
                            appointmentTime = "8:00 AM";
                        } else if (this.selectedWeekDay.slot2.status === 'selected') {
                            appointmentTime = "12:30 PM";
                        }

                        this.selectedTimePeriod = appointmentTime + " " + this.selectedWeekDay.date.weekdayLong + " " + this.selectedWeekDay.date.toLocaleString(DateTime.DATE_FULL);
                    } else if (step === 8) {
                        this.title = "Thank You";
                    } else if (step === 9) {
                        this.title = "Appointment Status";
                    }
                }

                if (!this.errorMessage) {
                    this.currentStep = step;
                    setTimeout(() => {
                        if (step < 8) {
                            this.stepper.selectedIndex = StepsJumpsTables.StepperIndexByStepGet(
                                this.userType, this.appointAssistanceLinkVisibleForFlorida, step);
                        }
                    })
                }
            }
        }
    }

    getOrgId() {
        let params = new GetAsiOrgIdRequest();
        params.ACVID = this.dealerId;
        this.acvService.GetASIOrgID(params).subscribe({
            next: (data: GetAsiOrgIdResponse) => {
                this.acvOrgId = data.AsiOrgId;
                this.acvOrgName = data.AsiOrgName;
                this.acvOrgCode = data.AsiOrgCode;
            },
            error: error => {
                this.loading = false;
                console.error('There was an error!', error);
            }
        });
    }

    searchVIN() {
        this.step1.markAllAsTouched();
        if (this.userType === "dealer") {
            this.step1.controls['accountNumber'].setValue(this.userId);
        }
        if (this.step1.valid) {
            this.loading = true;
            let params = new GetValidWorkItemRequest();
            params.Vehicle_VIN = this.step1.controls['vin'].value;
            params.AccountNumber = this.step1.controls['accountNumber'].value;
            this.acvService.GetValidWorkItem(params).subscribe({
                next: (data: GetValidWorkItemResponse) => {
                    if (data.Result.ReturnCode === 0) {
                        this.isArfSubmittedForCurrentVin = Helper.IsVinCodeInArfArray(this.step1.controls['vin'].value);
                        this.assignmentId = data.WorkItem.Assignment_ID;
                        this.customerId = data.WorkItem.Customer_ID;
                        this.maturityDate = data.WorkItem.Maturity_Date;
                        this.customer_name = data.WorkItem.Customer_Name;
                        if (data.WorkItem.Vehicle_Color) {
                            this.color = data.WorkItem.Vehicle_Color;
                        }
                        this.step2.controls['zip'].setValue(data.WorkItem.PostalCode);
                        this.step2.controls['streetAddress1'].setValue(data.WorkItem.AddressLine1);
                        this.startmaturitydate = new Date(this.maturityDate);
                        new Date(this.startmaturitydate.setDate(this.startmaturitydate.getDate() - 15));
                        this.ccmaturity = new Date(this.maturityDate);
                        new Date(this.ccmaturity.setDate(this.ccmaturity.getDate() - 10));

                        this.maturityDateIsOver = this.isMaturityDateOver();
                        if (this.maturityDateIsOver) {
                            this.loading = false;
                        } else {
                            this.getWorkItem(1);
                        }
                    } else {
                        this.vinNotFound = true;
                        this.loading = false;
                    }
                },
                error: error => {
                    this.loading = false;
                    console.error('There was an error!', error);
                }
            });
        }
    }

    getWorkTypes() {
        let params = new GetWorkTypesRequest();

        if (this.userType === 'dealer') {
            // when userType is dealer and the VIN code is not available in database yet
            // the customerId is equal 0
            // (because of previous request /SearchWorkItems returned empty array of WorkItems
            // ---
            // work types are not available if customerId is equal 0 (because of it was not set)
            // for such new vin code the customer id will be set to acvOrgId
            // workType ACVHome will be returned for such a case
            if (this.customerId === 0 || this.customerId === "0") {
                params.customerID = this.acvOrgId;
            } else {
                params.customerID = this.customerId;
            }
        } else { // userType is 'customer'
            params.customerID = this.customerId;
        }
        params.AcvOrgId = this.acvOrgId;
        this.acvService.GetWorkTypes(params).subscribe({
            next: (data: GetWorkTypesResponse) => {
                if (data.Result.ReturnCode === 0) {
                    this.workType = data.WorkTypes[0].WorkType_Code;
                }
                this.getcities();
            },
            error: error => {
                this.loading = false;
                console.error('There was an error!', error);
            }
        });
    }

    getcities() {
        let params = new GetCitiesRequest();
        params.postalcode = this.step2.controls['zip'].value;
        this.acvService.GetCities(params).subscribe({
            next: (data: GetCitiesResponse) => {
                if (data.Result.ReturnCode === 0) {
                    this.Cities = data.Cities;
                    this.state = data.Cities[0].PostalState;
                    // SCH-948 task
                    if (this.Cities.length == 1) { // pre-select the city if the list has only one city for user's ZIP
                        this.step2.controls['city'].setValue(this.Cities[0].PostalCity);
                    } else {
                        let oldValue = this.step2.controls['city'].value;
                        if(!this.Cities.includes(oldValue)) {
                            let index = this.Cities.findIndex(item => item.PostalCity === oldValue);
                            if(index < 0) {
                                this.step2.controls['city'].setValue('');
                                this.step2.controls['city'].setErrors({'required': true});
                            }
                        }
                    }
                    this.step2.controls['state'].setValue(this.state);
                    this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms || Helper.IsStateFlorida(this.state);
                }
                this.getProfilesForWorkType();
            },
            error: error => {
                this.loading = false;
                console.error('Invalid Zipcode!', error);
            }
        });
    }

    getProfilesForWorkType() {
        let params = new GetProfilesForWorkTypeRequest();
        params.WorkType = this.workType;
        params.CustomerId = this.customerId;
        params.AcvOrgId = this.acvOrgId;

        if (this.workType === "FBI") {
            if (this.state === 'WI' || this.state === 'NH') {
                this.firstDates = DateTime.fromJSDate(this.startmaturitydate).setZone('America/New_York').startOf('week');
            }
        }

        this.acvService.GetProfilesForWorkType(params).subscribe({
            next: (data: GetProfilesForWorkTypeResponse) => {
                if (data.ReturnCode === 0) {
                    if (data.Profiles.length === 0) {
                        this.loading = false
                        alert('Profiles list is empty')
                    } else {
                        this.workTypeProfile = data.Profiles[0].ProfileName;
                        if (this.userType === "consumer") {
                            this.loading = false;
                            this.navigate(2);
                        } else if (this.userType === "dealer" && this.currentStep > 2) {
                            this.getAvailableTimes();
                        } else if (this.userType === "dealer") {
                            this.loading = false;
                            if (this.currentStep === 1) {
                                this.navigate(2);
                            }
                        }
                    }
                }
            },
            error: error => {
                this.loading = false;
                console.error('There was an error!', error);
                alert(error.message)
            }
        });
    }

    // CkeckVIN() {

    //     this.loading = true;
    //     var dealer_id = this.cookieService.get('dealerId');
    //     if (this.A_C_status === 'Scheduled') //|| this.checking2 === 'Open')
    //     {
    //         if (this.getDealerID === dealer_id)
    //         {
    //             this.loading = true;
    //         }
    //         else
    //         {
    //             this.loading = false;
    //             this.step1Error = "At this time, the vehicle you are attempting to schedule for an inspection currently has an appointment on file." +
    //                           "  Please contact the Seller to determine if another Dealer has scheduled an appointment for the same vehicle."
    //         }
    //     }
    //     else
    //     {
    //         this.loading = true;
    //     }
    // }

    createAssignment() {
        this.loading = true;

        var maturityDate = new Date(); // Now
        maturityDate.setDate(maturityDate.getDate() + 30); // Set now + 30 days as the new date

        if (!this.accountId) {
            this.accountId = String(Math.floor(Math.random() * 1000000000));
            this.accountId = "1" + this.accountId;
        }
        let params = new CreateOlWorkItemRequest();
        params.AccountNumber = this.accountId;
        params.AcvOrgID = this.dealerId;
        params.Address_1 = this.step2.controls['streetAddress1'].value;
        params.Address_2 = this.step2.controls['streetAddress2'].value;
        params.City = this.step2.controls['city'].value;
        params.State = this.step2.controls['state'].value;
        this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms || Helper.IsStateFlorida(params.State);
        params.Zip = this.step2.controls['zip'].value;
        params.Comments = this.step2.controls['additionalInfo'].value;
        params.Contact_Home_Phone = this.step3.controls['ownerPhone'].value;
        params.Contact_Name = this.step3.controls['ownerName'].value;
        params.Maturity_Date = maturityDate.toISOString();
        params.Vehicle_VIN = this.step1.controls['vin'].value;
        params.Vehicle_Year = this.step1.controls['year'].value;
        this.acvService.CreateOLWorkItem(params).subscribe({
            next: (data: CreateOlWorkItemResponse) => {
                this.assignmentId = data.AssignmentID;
                this.getWorkItem(4);
            },
            error: error => {
                if (this.currentStep === 2) {
                    this.step2Error = "Looks like there was an error. Please click button again. If you continue to have this issue, please refresh and try again.";
                }
                if (this.currentStep === 3) {
                    this.step3Error = "We regret to inform you that no appointment slots are currently available at this location.  Please proceed to fill out the provided Availability Request Form.  We will promptly address your request and provide a response within 48 hours. Thank you for your patience.";
                }
                this.loading = false;
                console.error('There was an error!', error);
            }
        });
    }

    getWorkItem(step: number) {
        let params = new GetWorkItemSaRequest();
        params.assignmentid = this.assignmentId;
        this.acvService.GetWorkItemSA(params).subscribe({
            next: (data: GetWorkItemSaResponse) => {
                if (data.ReturnCode === 0) {
                    this.maturityDate = data.WorkItemSAs[0].WorkItem.Maturity_Date;
                    this.customerId = data.WorkItemSAs[0].WorkItem.Customer_ID;
                    if (data.WorkItemSAs[0].WorkItem.Make) {
                        this.step1.controls['make'].setValue(data.WorkItemSAs[0].WorkItem.Make);
                    }
                    if (data.WorkItemSAs[0].WorkItem.Model) {
                        this.step1.controls['model'].setValue(data.WorkItemSAs[0].WorkItem.Model);
                    }
                    if (data.WorkItemSAs[0].WorkItem.Vehicle_Year) {
                        this.step1.controls['year'].setValue(data.WorkItemSAs[0].WorkItem.Vehicle_Year);
                    }
                    //this.checking2 = data.WorkItemSAs[0].WorkItem.Internal_StatusCode;
                    this.getDealerID = data.WorkItemSAs[0].WorkItem.AcvOrgId;
                    // if (data.WorkItemSAs[0].WorkSA !== null)
                    // {
                    // this.A_C_status = data.WorkItemSAs[0].WorkSA.Appointment_Current_Status;
                    // }
                    if (this.userType === 'consumer') {
                        if (this.currentStep === 1) {
                            if (data.WorkItemSAs[0].WorkItem.Make === 'Honda' || data.WorkItemSAs[0].WorkItem.Make === 'HONDA') {
                                this.openhondasurveydialog();
                            } else if (data.WorkItemSAs[0].WorkItem.Make === 'Acura' || data.WorkItemSAs[0].WorkItem.Make === 'ACURA') {
                                this.openacurasurveydialog();
                            }
                        }
                    }


                    if (step === 1) {
                        if (data.WorkItemSAs.length > 0) {

                            // if (this.userType === 'dealer')
                            // {
                            // this.CkeckVIN();
                            // }
                            /*
                            * WorkSA Appointment_Current_Status: "Scheduled" then its a reschedule
                            * !workSA go into WorkItem Internal_StatusCode: "Cancelled"
                            *
                            * */
                            this.step5.controls['name'].setValue(data.WorkItemSAs[0].WorkItem.Contact_Name);
                            this.step5.controls['email'].setValue(data.WorkItemSAs[0].WorkItem.Contact_Email);
                            this.step5.controls['mobileNumber'].setValue(data.WorkItemSAs[0].WorkItem.Contact_Home_Phone);

                            if (data.WorkItemSAs[0].FslWork !== null) {
                                let scheduled = false;
                                if (data.WorkItemSAs[0].WorkSA !== null && data.WorkItemSAs[0].WorkSA.Appointment_Current_Status === 'Scheduled') {
                                    scheduled = true;
                                }
                                if (data.WorkItemSAs[0].FslWork.workDetail !== null && data.WorkItemSAs[0].FslWork.workDetail.status === 'Scheduled') {
                                    scheduled = true;
                                }
                                if (data.WorkItemSAs[0].WorkSA === null && data.WorkItemSAs[0].WorkItem.Internal_StatusCode === 'Cancelled') {
                                    scheduled = true;
                                }
                                if (scheduled) {
                                    if (data.WorkItemSAs[0].WorkSA !== null) {
                                        var appointmentDate = data.WorkItemSAs[0].WorkSA.Appointment_Date;
                                        var dateSplit = appointmentDate.split(" ");
                                        if (data.WorkItemSAs[0].WorkSA.Appointment_Type) {
                                            this.appointmentType = data.WorkItemSAs[0].WorkSA.Appointment_Type;
                                        }
                                        this.selectedTimePeriod = dateSplit[0] + " " + data.WorkItemSAs[0].WorkSA.Appointment_Time;
                                    } else {
                                        this.step1Error = "There was an error! Please call 1-800-340-4080 to address the issue.";
                                        this.loading = false;
                                    }

                                    if (!this.step1Error) {
                                        this.confirmationNumber = data.WorkItemSAs[0].FslWork.uuid;
                                        if (data.WorkItemSAs[0].FslWork.contactInfo.name) {
                                            this.step5.controls['name'].setValue(data.WorkItemSAs[0].FslWork.contactInfo.name);
                                        }

                                        if (data.WorkItemSAs[0].FslWork.contactInfo.email) {
                                            this.step5.controls['email'].setValue(data.WorkItemSAs[0].FslWork.contactInfo.email);
                                        }

                                        if (data.WorkItemSAs[0].FslWork.contactInfo.phoneNumber) {
                                            this.step5.controls['mobileNumber'].setValue(data.WorkItemSAs[0].FslWork.contactInfo.phoneNumber);
                                        }

                                        if (data.WorkItemSAs[0].FslWork.vehicle.make) {
                                            this.step1.controls['make'].setValue(data.WorkItemSAs[0].FslWork.vehicle.make);
                                        }

                                        if (data.WorkItemSAs[0].FslWork.vehicle.model) {
                                            this.step1.controls['model'].setValue(data.WorkItemSAs[0].FslWork.vehicle.model);
                                        }

                                        if (data.WorkItemSAs[0].FslWork.vehicle.year) {
                                            this.step1.controls['year'].setValue(data.WorkItemSAs[0].FslWork.vehicle.year);
                                        }
                                        if (data.WorkItemSAs[0].FslWork.vehicle.series) {
                                            this.series = data.WorkItemSAs[0].FslWork.vehicle.series;
                                        }


                                        if (data.WorkItemSAs[0].FslWork.address.street) {
                                            this.step2.controls['streetAddress1'].setValue(data.WorkItemSAs[0].FslWork.address.street);
                                        }

                                        if (data.WorkItemSAs[0].FslWork.address.city) {
                                            this.step2.controls['city'].setValue(data.WorkItemSAs[0].FslWork.address.city);
                                        }

                                        if (data.WorkItemSAs[0].FslWork.address.state) {
                                            this.step2.controls['state'].setValue(data.WorkItemSAs[0].FslWork.address.state);
                                            this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms ||
                                                Helper.IsStateFlorida(data.WorkItemSAs[0].FslWork.address.state);
                                        } else {
                                            this.appointAssistanceLinkVisibleForFlorida = false;
                                        }

                                        if (data.WorkItemSAs[0].FslWork.address.postalCode) {
                                            this.step2.controls['zip'].setValue(data.WorkItemSAs[0].FslWork.address.postalCode);
                                        }

                                        if (data.WorkItemSAs[0].FslWork.workDetail.drivingDirections) {
                                            this.step2.controls['drivingDirections'].setValue(data.WorkItemSAs[0].FslWork.workDetail.drivingDirections);
                                        }

                                        if (data.WorkItemSAs[0].WorkItem.Contact_Home_Phone) {
                                            this.step3.controls['ownerPhone'].setValue(data.WorkItemSAs[0].WorkItem.Contact_Home_Phone);
                                        }
                                        if (data.WorkItemSAs[0].WorkItem.Contact_Name) {
                                            this.step3.controls['ownerName'].setValue(data.WorkItemSAs[0].WorkItem.Contact_Name);
                                        }

                                        this.currentStep = 9;
                                        this.loading = false;
                                        this.navigate(this.currentStep);
                                    }
                                } else {
                                    // this.currentStep = 2;
                                    // this.navigate(this.currentStep);
                                    // this.loading = false;
                                    this.getWorkTypes();
                                }
                            } else {
                                // this.currentStep = 2;
                                // this.navigate(this.currentStep);
                                // this.loading = false;
                                this.getWorkTypes();
                            }
                        } else {
                            this.currentStep = 3;
                            this.navigate(this.currentStep);
                        }
                    } else if (step === 4) {
                        if (data.WorkItemSAs[0].FslWork !== null) {
                            if (data.WorkItemSAs[0].FslWork.workDetail.status === "Scheduled") {
                                var appointmentDate = data.WorkItemSAs[0].WorkSA.Appointment_Date;
                                var dateSplit = appointmentDate.split(" ");
                                if (data.WorkItemSAs[0].WorkSA.Appointment_Type) {
                                    this.appointmentType = data.WorkItemSAs[0].WorkSA.Appointment_Type;
                                }

                                this.selectedTimePeriod = dateSplit[0] + " " + data.WorkItemSAs[0].WorkSA.Appointment_Time;

                                this.confirmationNumber = data.WorkItemSAs[0].FslWork.uuid;
                                this.step5.controls['name'].setValue(data.WorkItemSAs[0].FslWork.contactInfo.name);
                                this.step5.controls['email'].setValue(data.WorkItemSAs[0].FslWork.contactInfo.email);
                                this.step5.controls['mobileNumber'].setValue(data.WorkItemSAs[0].FslWork.contactInfo.phoneNumber);
                                this.step1.controls['make'].setValue(data.WorkItemSAs[0].FslWork.vehicle.make);
                                this.step1.controls['model'].setValue(data.WorkItemSAs[0].FslWork.vehicle.model);
                                this.step1.controls['year'].setValue(data.WorkItemSAs[0].FslWork.vehicle.year);

                                this.step2.controls['streetAddress1'].setValue(data.WorkItemSAs[0].FslWork.address.street);
                                this.step2.controls['city'].setValue(data.WorkItemSAs[0].FslWork.address.city);
                                this.step2.controls['state'].setValue(data.WorkItemSAs[0].FslWork.address.state);

                                this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms ||
                                    Helper.IsStateFlorida(data.WorkItemSAs[0].FslWork.address.state);

                                this.step2.controls['zip'].setValue(data.WorkItemSAs[0].FslWork.address.postalCode);
                                this.step2.controls['drivingDirections'].setValue(data.WorkItemSAs[0].FslWork.workDetail.drivingDirections);
                                this.currentStep = 8;

                                this.navigate(this.currentStep);
                                this.loading = false;
                            } else {
                                this.getWorkTypes();
                            }
                        } else {
                            this.getWorkTypes();
                        }
                    } else if (step === 8) {
                        if (data.WorkItemSAs.length > 0) {
                            if (data.WorkItemSAs[0].FslWork.workDetail.status === "Scheduled") {
                                this.confirmationNumber = data.WorkItemSAs[0].FslWork.uuid;
                                this.createCalendarFile();
                            }
                        }
                    }

                } else {
                    if (step === 1) {
                        this.step1Error = "Looks like there was an error. Please click button again. If you continue to have this issue, please refresh and try again.";
                    } else if (step === 4) {
                        this.step4Error = "Looks like there was an error. Please click button again. If you continue to have this issue, please refresh and try again.";
                    }
                }
            },
            error: error => {
                this.loading = false;
                if (step === 1) {
                    this.step1Error = "Looks like there was an error. Please click button again. If you continue to have this issue, please refresh and try again.";
                } else if (step === 4) {
                    this.step4Error = "Looks like there was an error. Please click button again. If you continue to have this issue, please refresh and try again.";
                }
            }
        });
    }

    cancelAppointment(reschedule: boolean) {
        if (reschedule === true) {
            this.loading = true;
            this.firstCalendarLoad = false;
        } else {
            this.cancelling = true;
        }
        let params = new CancelSaRequest();
        params.assignmentid = this.assignmentId;
        params.postalcode = this.step2.controls['zip'].value
        this.acvService.CancelSA(params).subscribe({
            next: (data: CancelSaResponse) => {
                if (reschedule === true) {
                    this.loading = false;
                } else {
                    this.cancelling = false;
                }
                if (data.ReturnCode === 0) {
                    let currentStep = 1;
                    if (reschedule) {
                        this.getWorkTypes();
                        this.navigate(2, true);
                        this.selectedtime = true;
                    } else {
                        window.location.reload();
                        // this.navigate(1, true);
                    }


                }
            },
            error: error => {
                this.loading = false;
                console.error('There was an error!', error);
            }
        });
    }

    logout() {
        this.cookieService.delete('True360');
        this.cookieService.delete('dealerId');
        this.cookieService.delete('userId');
        this.router.navigate(['/'])
    }


    isMaturityDateOver(): boolean {
        // for Chrysler Capital maturity date is decreased by 10 days
        if (this.customer_name === "Chrysler Capital") {
            this.Cryslerdate = DateTime.fromJSDate(this.ccmaturity);
            if (this.Cryslerdate <= this.today) {
                return true;
            }
        }
        // for other customers maturity date is compared with today
        let mtrtyDate: DateTime = DateTime.fromJSDate(new Date(this.maturityDate));
        if (mtrtyDate <= this.today) {
            return true;
        }
        return false;
    }

    getAvailableTimes() {
        // set ARF button visibility for current session
        this.isArfSubmittedForCurrentVin = Helper.IsVinCodeInArfArray(this.step1.controls['vin'].value);

        this.loading = true;
        let params = new GetSaTimeSlotsRequest();
        params.AssignmentId = this.assignmentId;
        params.Street = this.step2.controls['streetAddress1'].value;
        params.City = this.step2.controls['city'].value;
        params.State = this.step2.controls['state'].value;
        this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms || Helper.IsStateFlorida(params.State);

        params.PostCode = this.step2.controls['zip'].value;
        params.WorkType = this.workType;
        params.TimeProfile = this.workTypeProfile;

        if (this.currentFromDateForCache.toUnixInteger() === this.epochStart.toUnixInteger()) {
            this.timeSlotsHadBeenReceivedFirstTime = true;
            // dates for time slots cache retrieving are set the very first time
            if (this.state === 'WI' || this.state === 'NH') {
                this.currentFromDateForCache = this.firstDates;
                this.currentToDateForCache = this.firstDates.endOf('week')
                    .plus({days: (weeksNumberToCacheTimeSlots - 1) * 7});
                this.validstate = true;
            } else {
                this.currentFromDateForCache = this.firstDate;
                this.currentToDateForCache = this.lastDate
                    .plus({days: (weeksNumberToCacheTimeSlots - 1) * 7});
                this.validstate = false;
            }
            this.timeSlotsCacheHadBeenReceived = false; // time slots cache must be received
        } else {
            switch (this.state) {
                case 'WI':
                case 'NH': {
                    if (this.firstDates > this.currentToDateForCache) {
                        this.currentFromDateForCache = this.firstDates;
                        this.currentToDateForCache = this.firstDates.endOf('week')
                            .plus({days: (weeksNumberToCacheTimeSlots - 1) * 7});
                        this.timeSlotsCacheHadBeenReceived = false; // next cache must be received
                        this.timeSlotsHadBeenReceivedFirstTime = false;
                    }
                    break;
                }
                default: {
                    if (this.firstDate > this.currentToDateForCache) {
                        this.currentFromDateForCache = this.firstDate;
                        this.currentToDateForCache = this.lastDate
                            .plus({days: (weeksNumberToCacheTimeSlots - 1) * 7});
                        this.timeSlotsCacheHadBeenReceived = false; // next cache must be received
                        this.timeSlotsHadBeenReceivedFirstTime = false;
                    }
                }
            }
        }

        if (!this.timeSlotsCacheHadBeenReceived) {
            params.FromDate = this.currentFromDateForCache.toISODate();
            params.ToDate = this.currentToDateForCache.toISODate();
        }

        this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms || Helper.IsStateFlorida(this.state);

        if (this.timeSlotsCacheHadBeenReceived) {
            this.loading = false;
            if (this.timeSlotsHadBeenReceivedFirstTime && this.noTimeSlotsInsideCurrentWeek(this.timeSlots) &&
                (this.changeWeekCounterLimit >= this.changeWeekCounter)) {
                this.changeWeekCounter++;
                this.changeWeek('next'); // looking for a week with available time slots
            } else {
                this.buildTimeSlots(this.timeSlots, this.firstDate);
                this.navigate(4);
            }

        } else {
            this.acvService.GetSATimeSlots(params).subscribe({
                next: (data: GetSATimeSlotsResponse) => {
                    this.timeSlotsCacheHadBeenReceived = true;
                    this.loading = false;
                    if (data.ReturnCode === 0) {
                        if (data.AppointmentTimeSlots == null) {
                            // set to empty array to show error message
                            // instead of non-processed exception and frozen application
                            data.AppointmentTimeSlots = [];
                        }

                        this.timeSlots = this.timeSlots.concat(data.AppointmentTimeSlots);
                        this.sortTimeSlots();
                        this.removeTodayFromTimeSlots();
                        this.removePastTimeSlots();
                        this.keepUniqueTimeSlotsOnly();
                        this.removeTimeSlotsWithWrongTimeFrames();
                        if (this.timeSlots.length > 0) {
                            if (this.timeSlotsHadBeenReceivedFirstTime
                                && this.noTimeSlotsInsideCurrentWeek(this.timeSlots) &&
                                (this.changeWeekCounterLimit >= this.changeWeekCounter)) {
                                this.changeWeekCounter++;
                                this.changeWeek('next'); // looking for a week with available time slots
                            } else {
                                this.buildTimeSlots(this.timeSlots, this.firstDate);
                                this.navigate(4);
                            }
                        } else {
                            if (this.userType === "dealer") {
                                if (this.currentStep === 2) {
                                    this.step2Error = "We regret to inform you that no appointment slots are currently available at this location.  Please proceed to fill out the provided Availability Request Form.  We will promptly address your request and provide a response within 48 hours. Thank you for your patience.";
                                } else if (this.currentStep === 3) {
                                    this.step3Error = "We regret to inform you that no appointment slots are currently available at this location.  Please proceed to fill out the provided Availability Request Form.  We will promptly address your request and provide a response within 48 hours. Thank you for your patience.";
                                }
                            } else {
                                if (this.currentStep === 2) {
                                    this.step2Error = "There are no appointments available for your location. Please call 1-800-340-4080 to complete scheduling.";
                                } else if (this.currentStep === 3) {
                                    this.step3Error = "There are no appointments available for your location. Please call 1-800-340-4080 to complete scheduling.";
                                }
                            }
                        }
                    } else {
                        if (this.userType === 'dealer') {
                            if (this.currentStep === 3) {
                                // this.step3Error = "Looks like there was an error. Please complete the above Availability Request Form. We will respond back within 2 business days.";
                                // if (data.Message === " There were issues updating the Due Date and Earlist Start Time on Service Appointments. Please contact your Salesforce Administrator with following Error Message: Earliest Start Permitted must precede Scheduled Start and Arrival Window Start.") {
                                //     this.step3Error = "We had an issue retrieving appointment slots. Please call 1-800-340-4080 to complete scheduling.";
                                // }
                                //
                                // Message from Dorothy Robertson
                                // of 20-Sep-2023
                                // In the below listed case let's use the same error message
                                this.step3Error = "We regret to inform you that no appointment slots are currently available at this location.  Please proceed to fill out the provided Availability Request Form.  We will promptly address your request and provide a response within 48 hours. Thank you for your patience.";
                            }
                        } else {
                            if (this.currentStep === 2) {
                                this.step2Error = "Looks like there was an error. Please click button again. If you continue to have this issue, Please call 1-800-340-4080 to complete scheduling.";

                                if (data.Message === " There were issues updating the Due Date and Earlist Start Time on Service Appointments. Please contact your Salesforce Administrator with following Error Message: Earliest Start Permitted must precede Scheduled Start and Arrival Window Start.") {
                                    this.step2Error = "We had an issue retrieving appointment slots. Please call 1-800-340-4080 to complete scheduling.";
                                }
                            }
                        }
                    }
                },
                error: error => {
                    if (this.userType === 'dealer') {
                        if (this.currentStep === 2) {
                            this.step2Error = "We regret to inform you that no appointment slots are currently available at this location.  Please proceed to fill out the provided Availability Request Form.  We will promptly address your request and provide a response within 48 hours. Thank you for your patience.";
                        } else if (this.currentStep === 3) {
                            this.step3Error = "We regret to inform you that no appointment slots are currently available at this location.  Please proceed to fill out the provided Availability Request Form.  We will promptly address your request and provide a response within 48 hours. Thank you for your patience.";
                        }
                    } else {  //consumer
                        if (this.currentStep === 2) {
                            this.step2Error = "Looks like there was an error. Please click button again. If you continue to have this issue, Please call 1-800-340-4080 to complete scheduling.";
                        } else if (this.currentStep === 3) {
                            this.step3Error = "Looks like there was an error. Please click button again. If you continue to have this issue, Please call 1-800-340-4080 to complete scheduling.";
                        }
                    }

                    this.loading = false;
                    console.error('There was an error!', error);
                }
            });
        }
    }

    buildTimeSlots(timeSlots: Array<object>, firstDate: DateTime) {
        this.currentDates = [];
        if (this.state === 'WI' || this.state === 'NH') {
            firstDate = this.firstDates;
        }
        this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms || Helper.IsStateFlorida(this.state);

        for (let i = 0; i < 7; i++) {
            let dayDate = firstDate.plus({days: i});
            let availability = this.getDateAvailability(timeSlots, dayDate);

            this.currentDates.push({
                'date': dayDate,
                'name': dayDate.weekdayShort + " " + dayDate.day,
                'slot1': {
                    'status': availability.slot1,
                    'start_time': dayDate.toISODate() + " 08:00:00 AM",
                    'end_time': dayDate.toISODate() + " 12:30:00 PM"
                },
                'slot2': {
                    'status': availability.slot2,
                    'start_time': dayDate.toISODate() + " 12:30:00 PM",
                    'end_time': dayDate.toISODate() + " 05:00:00 PM"
                },
            });
            this.loadingSchedule = false;
        }
    }

    setWeeks() {
        for (let i = 0; i < 7; i++) {
            let dayDate = (this.firstDate).plus({days: i});

            if (i === 6) {
                this.lastDate = dayDate;
            }
        }
    }

    completeStep() {
        this.stepper.selected.completed = true;
    }

    changeWeek(direction: string) {
        let validated = true;

        if (this.currentStep === 2) {
            this.step2.markAllAsTouched();

            if (this.step2.invalid) {
                validated = false;
            }
        }

        if (this.currentStep === 3) {
            this.step3.markAllAsTouched();

            if (this.step3.invalid) {
                validated = false;
            }
        }

        if (validated) {
            this.loadingSchedule = true;
            this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms || Helper.IsStateFlorida(this.state);

            if (direction === 'now') {
                if (this.state === 'NH' || this.state === 'WI') {
                    this.firstDate = this.firstDates;
                } else {
                    this.firstDate = DateTime.now().setZone('America/New_York').startOf('week');
                }
                this.navigatorDate = this.firstDate;
                // initialize the variables to manage time slots cache
                this.initVariablesToManageTimeSlotsCache();

            } else if (direction === 'previous' && this.navigator > 0) {
                this.navigator -= 1;
                this.navigatorDate = this.firstDate = this.navigatorDate.minus({weeks: 1});
            } else if (direction === 'next') {
                this.navigator += 1;
                this.navigatorDate = this.firstDate = this.navigatorDate.plus({weeks: 1});
            }
            this.currentMonth = this.firstDate.monthLong;
            this.currentYear = this.firstDate.year;
            this.lastDate = this.firstDate.endOf('week');
            this.firstDates = this.firstDate;

            if (this.userType === 'consumer') {
                this.getAvailableTimes();
            } else if (this.userType === 'dealer') {
                if (this.firstCalendarLoad) {
                    this.createAssignment();
                } else {
                    this.getAvailableTimes();
                }
            }
            this.firstCalendarLoad = false;
        }
    }

    getDateAvailability(timeSlots: Array<any>, dayDate: DateTime) {
        let availability =
            {
                'slot1': 'unavailable',
                'slot2': 'unavailable',
            };

        timeSlots.forEach(function (timeSlot) {
            if (timeSlot.Date === dayDate.weekdayShort + " " + dayDate.toLocaleString(DateTime.DATE_SHORT)) {
                if (timeSlot.TimeFrame === "8:00 AM To 12:30 PM") {
                    availability.slot1 = 'available';
                } else if (timeSlot.TimeFrame === "12:30 PM To 5:00 PM") {
                    availability.slot2 = 'available';
                }
                // Roll back the time slot splitting (the back end did not allow it)
                // else if (timeSlot.TimeFrame === "8:00 AM To 5:00 PM" || timeSlot.TimeFrame === "7:00 AM To 5:00 PM") {
                //     // split the time slot which was not offered by SWISS
                //     // into two ones: "8:00 AM To 12:30 PM" and "12:30 PM To 5:00 PM"
                //     availability.slot1 = 'available';
                //     availability.slot2 = 'available';
                // }
            }
        });

        if (this.workType === "FBI") {
            this.mtrtydate = new Date(this.maturityDate);
            this.abbr_state = this.step2.controls['state'].value;
            this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms || Helper.IsStateFlorida(this.abbr_state);

            // AGirenko It should be BUG because of  '10/31/2023' > '11/17/2022' = false
            // Dates were compared as strings in legacy code.
            // The < and > operators compare strings in lexicographical order.
            // if (this.maturityDate > this.today.toFormat("MM/dd/yyyy")) {
            // the code below worked but the changed one is better methodically
            // if (this.mtrtydate > this.today) {
            if (DateTime.fromJSDate(this.mtrtydate) > this.today) {
                if (this.abbr_state === "WI" || this.abbr_state === "NH") {
                    this.W_N_maturity = DateTime.fromJSDate(this.mtrtydate);
                    this.W_N_startmaturity = DateTime.fromJSDate(this.startmaturitydate);

                    if (dayDate > this.W_N_maturity || dayDate < this.W_N_startmaturity) {
                        availability.slot1 = 'unavailable';
                        availability.slot2 = 'unavailable';
                    }

                    if (this.customer_name === "Chrysler Capital") {
                        this.Cryslerdate = DateTime.fromJSDate(this.ccmaturity);
                        if (this.Cryslerdate <= dayDate) {
                            availability.slot1 = 'unavailable';
                            availability.slot2 = 'unavailable';
                        }

                    }

                } else if (this.customer_name === "Chrysler Capital") {
                    this.Cryslerdate = DateTime.fromJSDate(this.ccmaturity);
                    if (dayDate >= this.Cryslerdate) {
                        availability.slot1 = 'unavailable';
                        availability.slot2 = 'unavailable';
                    }
                }
            } else {
                availability.slot1 = 'unavailable';
                availability.slot2 = 'unavailable';
            }
        }
        if (dayDate.day === this.today.day) {
            availability.slot1 = 'unavailable';
            availability.slot2 = 'unavailable';
        }

        return availability;
    }

    selectDay(weekday: WeekDay, slot: number) {
        this.resetWeekDays();

        if (slot === 1 && weekday.slot1.status == 'available') {
            weekday.slot1.status = 'selected';
        } else if (slot === 2 && weekday.slot2.status === 'available') {
            weekday.slot2.status = 'selected';
        }
        this.selectedWeekDay = weekday;

    }

    resetWeekDays() {
        this.currentDates.forEach(function (WeekDay) {
            if (WeekDay.slot1.status === 'selected') {
                WeekDay.slot1.status = 'available';
            }
            if (WeekDay.slot2.status === 'selected') {
                WeekDay.slot2.status = 'available';
            }
        });
    }

    bookTimeSlot() {
        this.step7Error = false;
        this.loading = true;
        var startTime = "";
        var endTime = "";

        if (this.selectedWeekDay.slot1.status === "selected") {
            startTime = this.selectedWeekDay.slot1.start_time;
            endTime = this.selectedWeekDay.slot1.end_time;
        } else if (this.selectedWeekDay.slot2.status === "selected") {
            startTime = this.selectedWeekDay.slot2.start_time;
            endTime = this.selectedWeekDay.slot2.end_time;
        }

        let params = new ReserveSaRequest();
        params.Appointment_EndTime = endTime;
        params.Appointment_StartTime = startTime;
        params.Assignment_Date = this.selectedWeekDay.date.toLocaleString(DateTime.DATE_SHORT);
        params.AssignmentId = this.assignmentId;
        params.Street = this.step2.controls['streetAddress1'].value;
        params.City = this.step2.controls['city'].value;
        params.State = this.step2.controls['state'].value;
        this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms || Helper.IsStateFlorida(params.State);

        params.Zipcode = this.step2.controls['zip'].value;
        params.ContactEmail = this.step5.controls['email'].value;
        params.ContactName = this.step5.controls['name'].value;
        params.ContactPhone = this.step5.controls['mobileNumber'].value;
        params.CustomerName = this.step5.controls['name'].value;
        params.MaturityDate = this.maturityDate;
        params.VIN = this.step1.controls['vin'].value;
        params.WorkType = this.workType;

        this.acvService.ReserveSA(params).subscribe({
            next: (data: ReserveSaResponse) => {
                if (data.ReturnCode === 0) {
                    this.getWorkItem(8);
                } else {
                    this.step7Error = true;
                    this.loading = false;
                }
            },
            error: error => {
                this.step7Error = true;
                this.loading = false;
            }
        });
    }

    saveEmail() {
        let appointmentTime = "";

        if (this.selectedWeekDay.slot1.status === "selected") {
            appointmentTime = "8:00 AM To 12:30 PM";
        } else if (this.selectedWeekDay.slot2.status === "selected") {
            appointmentTime = "12:30 PM To 05:00 PM";
        }

        let accountId = this.step1.controls['accountNumber'].value;
        if (this.userType === "dealer") {
            accountId = this.accountId;
        }

        let params = new SaveSawEmailRequest();
        params.AccountNumber = accountId;
        params.AcvOrgId = this.acvOrgId;
        params.AddressLine1 = this.step2.controls['streetAddress1'].value;
        params.AddressLine2 = this.step2.controls['streetAddress2'].value;
        params.Appointment_InspectionContact = this.step5.controls['name'].value;
        params.AppointmentDate = this.selectedWeekDay.date.toISODate();
        params.AppointmentTime = appointmentTime;
        params.assignmentId = this.assignmentId;
        params.City = this.step2.controls['city'].value;
        params.confirmationNumber = this.confirmationNumber;
        params.ContactEmail = this.step5.controls['email'].value;
        params.ContactMobileNumber = this.step5.controls['mobileNumber'].value;
        params.ContactPhoneNumber = this.step5.controls['mobileNumber'].value;
        params.ContactName = this.step5.controls['name'].value;
        params.CustomerEmail = this.step5.controls['email'].value;
        params.CustomerId = this.customerId;
        params.DispatchInstructions = this.step2.controls['additionalInfo'].value;
        params.DrivingDirections = this.step2.controls['drivingDirections'].value;
        params.Maturity_Date = this.maturityDate;
        params.Organization_ID = this.dealerId;
        params.DealerName = this.cookieService.get('dealershipcookie');
        params.PostalCode = this.step2.controls['zip'].value;
        params.SpokeWith = this.cookieService.get('dealerFullName');
        params.State = this.step2.controls['state'].value;
        this.appointAssistanceLinkVisibleForFlorida = this.userCanSendSms || Helper.IsStateFlorida(params.State);

        params.TaskType = this.workType;
        params.Vehicle_VIN = this.step1.controls['vin'].value;
        params.Year = this.step1.controls['year'].value;

        if (!this.assistHideTextFields) {
            params.isCEM = true;
            params.userid = this.assistUserId; // in accordance with feedback from Aaron it should be 2563
            // set in accordance with remark from Aaron Seidel: CEMEmail =  CEMFirstName + "|" + CEMLastName;
            params.CEMEmail = this.step6.controls['assistFirstName'].value.trim()
                + "|" + this.step6.controls['assistLastName'].value.trim();
            params.CEMSMSNumber = this.step6.controls['assistMobileNumber'].value.trim();
            params.CEMUpdateUser = this.step6.controls['assistNameOfPerson'].value.trim();
            params.sendByCEMSMS = true;
        } else {
            params.CEMUpdateUser = this.step5.controls['name'].value;
        }


        this.acvService.SaveSAwEmail(params).subscribe({
            next: (data: SaveSawEmailResponse) => {
                this.loading = false;
                //this.submitAppAssist(); // parameters had been added to SaveSAwEmail
                this.navigate(8);
            },
            error: error => {
                this.step7Error = true;
                this.loading = false;
                console.error('There was an error!', error);
            }
        });
    }


    // method to change sub-step
    changeSubStep(appAssistSubStepNew: number | null): void {
        this.appAssistSubStep = appAssistSubStepNew;
        if (appAssistSubStepNew === 2) {
            // change Yes/No radio buttons status
            this.step6.controls['enrolledInAppAssist'].setValue('0')
            this.step6.controls['agreeToSms'].setValue('0')
            this.enrolledInAppAssistChanged()
        }
    }

    moveStepper(stepperStep: number, appAssistSubStepNew: number | null = null) {
        if (appAssistSubStepNew != null) {
            this.changeSubStep(appAssistSubStepNew);
        }
        this.stepper.linear = false;
        this.editable = true;
        this.stepper._steps._results.forEach((_step: any, i: number) => {
            if (i >= stepperStep) {
                _step.editable = false;
                _step.completed = false;
            }
        })
// the table of correspondence between steps of stepper and form steps is used
        let jumpTable = StepsJumpsTables.StepsJumpTableGet(this.userType, this.appointAssistanceLinkVisibleForFlorida);
        setTimeout(() => {
            this.stepper.selectedIndex = stepperStep;
            this.currentStep = jumpTable[stepperStep].CurrStep;
            this.stepper.linear = true;
            this.editable = false;
            this.ClearErrorOnStepBack(stepperStep);
        }, 0)
    }

    returnTo(location: string) {
        this.editable = true;
        this.stepper.linear = false;
        setTimeout(() => {
            if (location === "contact" && this.userType === "dealer") {
                this.stepper.selectedIndex = 2;
            } else if (location === "contact" && this.userType === "consumer") {
                this.stepper.selectedIndex = 3;
            } else if (location === "address") {
                this.stepper.selectedIndex = 1;
            } else if (location === "appointment") {
                this.stepper.selectedIndex = 2;
            }
            this.currentStep = this.stepper.selectedIndex + 1;
            this.stepper.linear = true;
            this.editable = false;

            this.stepper._steps._results.forEach((_step: any, i: number) => {
                if (i >= this.stepper.selectedIndex) {
                    _step.editable = false;
                    _step.completed = false;
                }
            })
        })
    }

    searchWorkItems() {
        let params = new SearchWorkItemsRequest();
        params.userid = this.userId;
        params.customerid = this.customerId;
        params.search_type_value = this.step1.controls['vin'].value;
        this.acvService.SearchWorkItems(params).subscribe({
            next: (data: SearchWorkItemsResponse) => {
                if(data.WorkItems === null){
                    this.step1Error = "There was an error! Please call 1-800-340-4080.";
                    console.log("Work items are not available ('null')");
                    this.navigate(2);
                    this.loading = false;
                }
                else {
                    if (data.WorkItems.length > 0) {
                        this.assignmentId = data.WorkItems[data.WorkItems.length - 1].Assignment_ID;
                        this.accountId = data.WorkItems[data.WorkItems.length - 1].AccountNumber;
                        if (data.WorkItems[data.WorkItems.length - 1].Assignment_Date) {
                            this.assignmentDate = data.WorkItems[data.WorkItems.length - 1].Assignment_Date;
                        }
                        this.step1.controls['accountNumber'].setValue(data.WorkItems[data.WorkItems.length - 1].AccountNumber);
                        this.getWorkItem(1);
                    } else {
                        this.navigate(2);
                        this.loading = false;
                    }
                }
            },
            error: error => {
            }
        });
    }

    createCalendarFile() {
        var startTime = "";
        var endTime = "";

        if (this.selectedWeekDay.slot1.status === "selected") {
            startTime = this.selectedWeekDay.slot1.start_time;
            endTime = this.selectedWeekDay.slot1.end_time;
        } else if (this.selectedWeekDay.slot2.status === "selected") {
            startTime = this.selectedWeekDay.slot2.start_time;
            endTime = this.selectedWeekDay.slot2.end_time;
        }

        const event = {
            title: "ACV Inspection",
            start: startTime,
            end: endTime,
        };
        this.googleCalLink = google(event);
        this.iCalLink = ics(event);
        this.outlookCalLink = outlook(event);
        this.saveEmail();

    }

    openConfirmCancellationDialog(reschedule_appointment: boolean) {
        const dialogRef = this.dialog.open(ConfirmCancellationDialog, {
            width: '500px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.cancelAppointment(reschedule_appointment);
            }
        });
    }

    opencemupdatedialog() {
        const dialogRef = this.dialog.open(CemUpdateDialogComponent, {
            width: 'auto',
            autoFocus: false
        });
    }

    opendatewarningdialog() {
        const dialog = this.dialog.open(DateWarningDialogComponent, {
            width: 'auto',
        });
    }

    // this is outdated method to open Availability Request Form as GoogleDoc form
    // in accordance with task SCH-710 it is replaced by method
    GetForm() {
        window.open('https://docs.google.com/forms/d/1ES6aNuCBpJzxSl8qmt3BjOeqkq06nxGeK5zbWp_WfgA/edit', '_blank');
    }

    openhondasurveydialog() {
        const dialog = this.dialog.open(HondaSurveyDialogComponent, {
            width: 'auto',
            maxHeight: window.innerHeight + 'px',
        });
    }

    openacurasurveydialog() {
        const dialog = this.dialog.open(AcuraSurveyDialogComponent, {
            width: 'auto',
            maxHeight: window.innerHeight + 'px',
        });
    }

    openFAQdialog() {
        const dialog = this.dialog.open(ACVAnywhere_FAQ_Dialog_Component, {
            width: 'auto',
            autoFocus: false,
            maxHeight: window.innerHeight + 'px',
        });
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // may be send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // OldTODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        console.log(`HeroService: ${message}`);
    }

    // method returns true if Appointment is assigned by another dealer within next two weeks
    public CheckIfAppointmentScheduledWithinNext2Weeks(data: any): boolean {
        // get WorkItems array and all dates
        // Example "AppointmentDate": "11/29/2022 12:00:00 AM",
        // find min appointment date in the future
        // if min appointment date in the future is within 14 days from today, return true
        // otherwise return false

// it is "fake" true for error message testing
//         if(this.step1.controls['vin'].value === "3HGGK5H86LM724519"){
//             return true;
//         }

        // may return true for dealer only
        if (this.userType !== "dealer") {
            return false;
        }
        const maxFutureDate: Date = new Date(8640000000000000);
        const todayDate: Date = new Date(new Date().toISOString().slice(0, 10)); // to get date with 12:00:00 AM time
        let earliestFutureAppointmentDate: Date = maxFutureDate;
        let appointmentDealerId: string = "";
        if (data.WorkItems !== undefined) {
            if (data.WorkItems.length == 0) {
                return false;
            }
            // find minimal future date of appointment
            for (let i = 0; i < data.WorkItems.length; i++) {
                let item: any = data.WorkItems[i];
                if (item.AppointmentDate !== undefined) {
                    if (item.AppointmentDate.trim() === '') {
                        continue;
                    }
                    let appDate = new Date(new Date(item.AppointmentDate).toISOString().slice(0, 10));
                    if (appDate < todayDate) {   // it is the appointment in the past, ignore it
                        continue;
                    }
                    if (appDate < earliestFutureAppointmentDate) {
                        earliestFutureAppointmentDate = appDate;
                        appointmentDealerId = item.AcvOrgId !== undefined ? item.AcvOrgId : "";
                    }
                }
            }
            if (appointmentDealerId === this.dealerId) {
                return false; // the appointment is created by current dealer, do not pay attention to dates
            }
            // calculate days difference between earliestFutureAppointmentDate and todayDate
            // if it is less than 14, return true
            const TwoWeeksDays: number = 14;
            let daysDiff = this.calculateDaysBetweenDates(todayDate, earliestFutureAppointmentDate)
            if (daysDiff < TwoWeeksDays) {
                return true;
            }
        }
        return false; // data.WorkItems is undefined
    }

    public CheckIfAssignmentCreatedWithinPrevious2Weeks(data: any): boolean {
        // It may return true for dealer only. Always false for consumer.
        if (this.userType !== "dealer") {
            return false;
        }

        const minPastDate: Date = new Date(-8640000000000000);
        const todayDate: Date = new Date(new Date().toISOString().slice(0, 10)); // to get date with 12:00:00 AM time
        let latestAssignmentDateInThePast: Date = minPastDate;
        let assignmentDealerId: string = "";
        if (data.WorkItems !== undefined) {
            if (data.WorkItems.length == 0) {
                return false;
            }
            // find minimal future date of appointment
            for (let i = 0; i < data.WorkItems.length; i++) {
                let item: any = data.WorkItems[i];
                if (item.Assignment_Date !== undefined) {
                    if (item.Assignment_Date.trim() === '') {
                        continue;
                    }
                    let assignmentDate = new Date(new Date(item.Assignment_Date).toISOString().slice(0, 10));
                    if (assignmentDate > latestAssignmentDateInThePast) {
                        latestAssignmentDateInThePast = assignmentDate;
                        assignmentDealerId = item.AcvOrgId !== undefined ? item.AcvOrgId : "";
                    }
                }
            }
            if (assignmentDealerId === this.dealerId) {
                return false; // the appointment is created by current dealer, do not pay attention to dates
            }
            // calculate days difference between latestAssignmentDateInThePast and todayDate
            // if it is less than 14, return true
            const TwoWeeksDays: number = 14;
            let daysDiff = this.calculateDaysBetweenDates(latestAssignmentDateInThePast, todayDate)
            if (daysDiff < TwoWeeksDays) {
                return true;
            }
        }
        return false; // data.WorkItems is undefined
    }


    // method calculates the difference in days between two dates
    // it is supposed that the secondDate is later than the firstDate
    private calculateDaysBetweenDates(firstDate: Date, secondDate: Date): number {
        // https://www.geeksforgeeks.org/how-to-calculate-the-number-of-days-between-two-dates-in-javascript/
        // calculate the time difference of two dates
        let Difference_In_Time = secondDate.getTime() - firstDate.getTime();  // milliseconds since "Unix Epoch"
        // calculate the number of days between two dates
        return Difference_In_Time / (1000 * 3600 * 24);
    }

    zipchange() {
        if (this.step2.controls['zip'].invalid) {
            this.step2.markAllAsTouched();
            return;
        }
        if (this.userType === 'dealer') {
            this.getWorkTypes();
        } else {
            this.getcities();
        }
    }

    enrolledInAppAssistChanged() {
        this.assistEnrolledInAppAssistVal = this.step6.controls['enrolledInAppAssist'].value === '1';
        this.assistAgreeToSmsVal = this.step6.controls['agreeToSms'].value === '1';
        this.assistHideTextFields = !(this.assistEnrolledInAppAssistVal && this.assistAgreeToSmsVal);
    }

    submitAppAssist() {

        // this.assistHideTextFields is true if
        // a) step6 was not opened
        // b) or user is not enrolled or does not agree to receive SMS
        if (this.assistHideTextFields) {
            return; // user is not enrolled or does not agree to SMS. No appointment assistance SMS will be sent
        }

        let appointmentTime = "";
        if (this.selectedWeekDay.slot1.status === "selected") {
            appointmentTime = "8:00 AM To 12:30 PM";
        } else if (this.selectedWeekDay.slot2.status === "selected") {
            appointmentTime = "12:30 PM To 05:00 PM";
        }
        let accountId = this.step1.controls['accountNumber'].value;
        if (this.userType === "dealer") {
            accountId = this.accountId;
        }

        let params = new SaveSawEmailRequest();
        // In accordance with feedback from Aaron Seidel the property below must be set to true
        // to mark VINs as AppointmentAssistant
        params.isCEM = true;
        params.userid = this.assistUserId; // in accordance with feedback from Aaron it should be 2563
        params.AccountNumber = accountId;
        params.AcvOrgId = this.acvOrgId;
        params.AddressLine1 = this.step2.controls['streetAddress1'].value;
        params.AddressLine2 = this.step2.controls['streetAddress2'].value;
        params.Appointment_InspectionContact = this.step5.controls['name'].value;
        params.AppointmentDate = this.selectedWeekDay.date.toISODate();
        params.AppointmentTime = appointmentTime;
        params.assignmentId = this.assignmentId;
        params.City = this.step2.controls['city'].value;
        params.confirmationNumber = this.confirmationNumber;
        params.ContactEmail = this.step5.controls['email'].value;
        params.ContactMobileNumber = this.step5.controls['mobileNumber'].value;
        params.ContactPhoneNumber = this.step5.controls['mobileNumber'].value;
        params.ContactName = this.step5.controls['name'].value;
        params.CustomerEmail = this.step5.controls['email'].value;
        params.CustomerId = this.customerId;
        params.DispatchInstructions = this.step2.controls['additionalInfo'].value;
        params.DrivingDirections = this.step2.controls['drivingDirections'].value;
        params.Maturity_Date = this.maturityDate;
        params.Organization_ID = this.dealerId;
        params.DealerName = this.cookieService.get('dealershipcookie');
        params.PostalCode = this.step2.controls['zip'].value;
        params.SpokeWith = this.cookieService.get('dealerFullName');
        params.State = this.step2.controls['state'].value;
        params.TaskType = this.workType;
        params.Vehicle_VIN = this.step1.controls['vin'].value;
        params.Year = this.step1.controls['year'].value;
        // set in accordance with remark from Aaron Seidel: CEMEmail =  CEMFirstName + "|" + CEMLastName;
        params.CEMEmail = this.step6.controls['assistFirstName'].value.trim()
            + "|" + this.step6.controls['assistLastName'].value.trim();
        params.CEMSMSNumber = this.step6.controls['assistMobileNumber'].value.trim();
        params.CEMUpdateUser = this.step6.controls['assistNameOfPerson'].value.trim();

        this.acvService.SaveAppointmentAssistanceDetails(params).subscribe({
            next: (data: SaveSawEmailResponse) => {
            },
            error: error => {
                console.error('There was a submit data error!', error);
            }
        });
    }

    ClearErrorOnStepBack(stepperStep: number): void {
        // The purpose of this method is to clear
        // error messages when a user decides to go one step back
        // errors are cleared for current step and for the next step
        let jumpTable = StepsJumpsTables.StepsJumpTableGet(this.userType, this.appointAssistanceLinkVisibleForFlorida);

        switch (jumpTable[stepperStep].CurrStep) {
            case 1: {
                this.step1Error = '';
                break;
            }
            case 2: {
                this.step2Error = '';
                break;
            }
            case 3: {
                this.step3Error = '';
                break;
            }
            case 4: {
                this.step4Error = '';
                break;
            }
            case 7: {
                this.step7Error = false;
                break;
            }
            default: break;
        }
        switch (jumpTable[stepperStep].Step) {
            case 1: {
                this.step1Error = '';
                break;
            }
            case 2: {
                this.step2Error = '';
                break;
            }
            case 3: {
                this.step3Error = '';
                break;
            }
            case 4: {
                this.step4Error = '';
                break;
            }
            case 7: {
                this.step7Error = false;
                break;
            }
            default: break;
        }
        this.errorMessage = '';
    }

    ArfDialogShow() {
        console.log("VIN on main form: ", this.step1.controls['vin'].value);
        let arfRequestData = new CreateArfRequest();
        arfRequestData.AppointmentType = this.appointmentType;
        if (this.assignmentDate.trim() === '') {
            // it was agreed with back end developer that this date to be set to today if absent
            let todayDate: string = DateTime.fromJSDate(new Date()).toLocaleString(DateTime.DATE_SHORT);
            arfRequestData.Assignment_Date = todayDate;
        } else {
            arfRequestData.Assignment_Date = this.assignmentDate;
        }
        arfRequestData.AssignmentId = this.assignmentId.toString();
        arfRequestData.City = this.step2.controls['city'].value;
        arfRequestData.Color = this.color;
        arfRequestData.CustomerName = this.customer_name;
        arfRequestData.LesseeName = this.step3.controls['ownerName'].value;
        arfRequestData.Make = this.step1.controls['make'].value.trim();
        arfRequestData.MaturityDate = this.maturityDate;
        arfRequestData.Model = this.step1.controls['model'].value.trim();
        arfRequestData.Series = this.series;
        arfRequestData.State = this.step2.controls['state'].value.trim();
        arfRequestData.Street = (this.step2.controls['streetAddress1'].value.trim() + ' '
            + this.step2.controls['streetAddress2'].value.trim()).trim();
        arfRequestData.VIN = this.step1.controls['vin'].value;
        arfRequestData.WorkType = this.workType;
        arfRequestData.Zipcode = this.step2.controls['zip'].value.trim();
        arfRequestData.IsARF = 'true';
        // from Kamlesh: You can use userid 2563. I found it being used in some api methods.
        arfRequestData.UserId = 2563;
        arfRequestData.CountryCode = "US";
        arfRequestData.ContactEmail = this.step5.controls['email'].value.trim();

        const arfDialog =
            this.dialog.open(ArfDialogComponent, {
                width: 'auto',
                maxHeight: window.innerHeight + 'px',
                disableClose: true,
                data: {
                    dataToRequest: arfRequestData
                }
            });
        arfDialog.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result.vin}   ${result.submitted}`);
            if (result.submitted) {
                Helper.AddVinCodeWithArfToSessionArray(result.vin);
                // code to manage ARF visibility button
                let currentVin = this.step1.controls['vin'].value.trim();
                this.isArfSubmittedForCurrentVin = Helper.IsVinCodeInArfArray(currentVin);
                window.location.reload(); // reload the page and return to the first step
            }
        });
    }
}

export function NumberMustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value.replace(/\D+/g, '') !== matchingControl.value.replace(/\D+/g, '')) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    }
}
