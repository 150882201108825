import {Component} from "@angular/core";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-about-vin-dialog',
    templateUrl: './about-vin-dialog.html',
    styleUrls: ['./about-vin-dialog.css']
})
export class AboutVinDialogComponent {
    constructor(public dialogRef: MatDialogRef<AboutVinDialogComponent>) {
    }

    close(): void {
        this.dialogRef.close();
    }
}
