<p mat-dialog-content class="text-center mx-0 px-o">If you continue, your existing appointment will be cancelled. Do you wish to continue?</p>
<div mat-dialog-actions class="d-flex justify-content-between" >
    <button mat-raised-button mat-dialog-close class="btn btn-danger text-white fw-bold">No</button>
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-success text-white fw-bold">Yes</button>
</div>
<style>
    p {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        padding: 0;
    }
</style>
