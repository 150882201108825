import {Component, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../environments/environment";
import {CookieService} from "ngx-cookie-service";
import {ACVAnywhere_FAQ_Dialog_Component} from './dialogs/FAQ_ACVAnywhere_dialog/FAQ_dialog.component';
import {Helper} from '../helpers/helper'

export interface DialogData {
    data: any;
    floatLabelControl: UntypedFormControl;
    username: string;
    password: string;
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        // 'Access-Control-Allow-Headers': 'Content-Type Accept',
        // 'Access-Control-Allow-Origin': '*',
    })
};

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'acv-scheduler';
    floatLabelControl = new UntypedFormControl('auto');
    username = "";
    password = "";

    constructor(public dialog: MatDialog, public router: Router, private cookieService: CookieService) {
    }


    loginForm() {
        let true360 = this.cookieService.get('True360');
        let dealerId = this.cookieService.get('dealerId');
        let userId = this.cookieService.get('userId');
        let dealerFullName = this.cookieService.get('dealerFullName');

        if (true360) {
            var cookie_date = new Date();
            cookie_date.setTime(cookie_date.getTime() + (30 * 60 * 1000));

            this.cookieService.set('True360', true360, {expires: cookie_date, path: '/'})
            if (dealerId)
                this.cookieService.set('dealerId', dealerId, {expires: cookie_date, path: '/'})

            if (userId)
                this.cookieService.set('userId', userId, {expires: cookie_date, path: '/'})

            if (dealerFullName)
                this.cookieService.set('dealerFullName', dealerFullName, {expires: cookie_date, path: '/'})

            //This is a hack to reload the same component without reloading the whole page
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigate(['dealer', 'schedule-an-appointment']);
            });
        } else {
            this.dialog.open(DialogLogin, {
                data: {floatLabelControl: this.floatLabelControl, username: this.username, password: this.password},
            });
        }
    }

    aboutTrue360() {
        window.open('https://true360.com/about', '_blank');
    }
}

@Component({
    selector: 'dialog-login',
    templateUrl: 'dialog-login.html',
    styleUrls: []
})
export class DialogLogin implements OnInit {
    errorMessage = "";
    loggingIn = false;
    username = "";
    password = "";
    apiUrl = environment.api_url;
    acvOrgId = 0;
    userId = 0;
    floatLabelControl = new UntypedFormControl('auto');
    step: UntypedFormGroup;
    dealerships: any[] = [];

    noActiveDealersError: string = ''

    constructor(
        public dialogRef: MatDialogRef<DialogLogin>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private http: HttpClient,
        private cookieService: CookieService,
        public dialog: MatDialog,
        private _formBuilder: UntypedFormBuilder,
    ) {
        this.step = this._formBuilder.group({
            dealership: ['', Validators.required],
        });
    }

    async ngOnInit() {
        this.step.controls['dealership'].setValidators([Validators.required]);
        this.step.controls['dealership'].updateValueAndValidity();
    }

    openFAQdialog() {
        this.dialog.open(ACVAnywhere_FAQ_Dialog_Component, {
            width: 'auto',
            autoFocus: false,
            maxHeight: window.innerHeight + 'px',
        });
    }

    login() {
        let dealerStatusUrl = `${environment.api_url}/GetDealerStatus`
        this.loggingIn = true;

        // login refactoring to remove duplicated code, Alex Girenko, 17-Feb-2023
        let loginUrl: string;
        if (environment.production) {
            loginUrl = 'https://mobile-gateway.acvauctions.com/api/legacylogin/v2'
        } else {
            loginUrl = 'https://mobile-gateway.latest.acvauctions.com/api/legacylogin/v2'
        }

        this.http.post<any>(loginUrl, {
            'email': this.username,
            'password': this.password,
            'web': true
        }, httpOptions).subscribe({
            next: data => {
                if (data.error) {
                    this.errorMessage = data.error.message;
                    this.loggingIn = false;
                } else {
                    let cookie_date = new Date();
                    cookie_date.setTime(cookie_date.getTime() + (30 * 60 * 1000));
                    this.cookieService.set('True360', data.jwtToken, {expires: cookie_date, path: '/'})
                    this.cookieService.set('dealerId', data.data.dealer_id, {expires: cookie_date, path: '/'})
                    this.cookieService.set('userId', data.data.user_id, {expires: cookie_date, path: '/'})
                    this.cookieService.set('dealerFullName', data.data.full_name, {expires: cookie_date, path: '/'})
                    this.dealerships = data.data.dealerships;
                    if (this.loggingIn == true) {
                        let dealerIdsToCheck = Helper.GetDealerIdsFromLoginDetails(data);
                        // this.cookieService.set('dealerIdsToCheck', Helper.SerializeToString(dealerIdsToCheck),
                        //     {expires: cookie_date, path: '/'});
                        Helper.DealersStatusGet(dealerStatusUrl,
                            dealerIdsToCheck,
                            httpOptions,
                            this.http)
                            .then((dealersStatus) => {
                                if (dealersStatus.ReturnCode !== 0) {
                                    throw new Error(dealersStatus.Message.replace('Error:', ''));
                                }
                                // this.cookieService.set('listOfDealersWhoCanNotSendSMS',
                                //     Helper.DealersListWhoNotAllowedToSendSmsGet(dealersStatus),
                                //     {expires: cookie_date, path: '/'})
                                this.cookieService.set('listOfDealersWhoCanSendSMS',
                                    Helper.DealersListWhoCanSendSmsGet(dealersStatus),
                                    {expires:cookie_date, path: '/'})
                                // keep only active in the list
                                data.data.dealerships = Helper.ActiveDealershipsGet(data.data.dealerships, dealersStatus);
                                this.dealerships = data.data.dealerships // to be consistent with legacy code.
                                // many active dealers
                                if (this.dealerships.length > 1) {
                                    this.dialog.open(DialogDealer, {data: {dataKey: data.data.dealerships}});
                                }

                                // single active dealer
                                if (this.dealerships.length === 1) {
                                    // set cookies for single dealer and route to scheduler (nothing to select because of single)
                                    cookie_date = new Date();
                                    cookie_date.setTime(cookie_date.getTime() + (30 * 60 * 1000));
                                    let dealer_id = this.dealerships[0].id
                                    let dealerName = this.dealerships[0].name
                                    this.cookieService.set('dealershipcookie', dealerName, {
                                        expires: cookie_date,
                                        path: '/'
                                    });
                                    this.cookieService.set('dealerId', dealer_id,
                                        {expires: cookie_date, path: '/'});
                                    this.router.navigate(['dealer', 'schedule-an-appointment']);
                                }
                                // Active dealers are absent
                                if (this.dealerships.length === 0) {

                                    // Show error message
                                    this.noActiveDealersError = "There are no dealership locations associated with these credentials.  Please try again or reach out to your account representative.";
                                    Helper.ClearCookies(this.cookieService);

                                }

                                this.loggingIn = false;
                                this.dialogRef.close();
                                if (this.noActiveDealersError) {
                                    alert(this.noActiveDealersError);
                                    this.noActiveDealersError = '';
                                } else {
                                    this.openFAQdialog();
                                }

                            })
                            .catch(err => {
                                this.loggingIn = false;
                                this.dialogRef.close();
                                alert(err);
                                console.error('There was an error!', err);
                                Helper.ClearCookies(this.cookieService);
                            })
                    }
                }
            },
            error: error => {
                this.loggingIn = false;
                this.dialogRef.close();
                console.error('There was an error!', error);
                Helper.ClearCookies(this.cookieService);
                alert("Invalid Username or Password!");
            }
        });
    }
}

@Component({
    selector: 'app-root',
    templateUrl: 'dialog-dealer.html',
    styleUrls: []
})
export class DialogDealer {
    dealerId = "";
    loggingIn = false;
    step: UntypedFormGroup;
    @Input() dealerships: any[] | undefined;

    constructor(
        public dialogRef: MatDialogRef<DialogDealer>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cookieService: CookieService,
        private _formBuilder: UntypedFormBuilder,
    ) {
        this.step = this._formBuilder.group({
            dealership: ['', Validators.required],
        });
    }

    Onclick() {
        var cookie_date = new Date();
        var dealerdroparray = this.step.controls['dealership'].value.split("ID:");
        var dealerdropcookie = dealerdroparray[0];
        var dealer_id = dealerdroparray[1];

        cookie_date.setTime(cookie_date.getTime() + (30 * 60 * 1000));
        this.cookieService.set('dealershipcookie', dealerdropcookie, {expires: cookie_date, path: '/'});
        this.cookieService.set('dealerId', dealer_id, {expires: cookie_date, path: '/'});
        if (this.step.controls['dealership'].value === "") {
            alert("Please select Dealership!");
            this.loggingIn = false;
        } else {
            this.router.navigate(['dealer', 'schedule-an-appointment']);
            this.dialogRef.close();
        }
    }

}
