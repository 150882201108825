import {Component} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { CookieService } from "ngx-cookie-service";
import { NumberMustMatch } from "src/app/scheduler/scheduler.component";

@Component({
    selector: 'app-root',
    templateUrl: 'cem-update-dialog.html',
})
export class CemUpdateDialogComponent {
    step0 : UntypedFormGroup;
    pname: string = '';
    enrollcem: any;
    enrollsms: any;
    mobileconfirm: string = '';
    mobileNumber: string = '';
    loading = false;
    constructor(public dialogRef: MatDialogRef<CemUpdateDialogComponent>,
                private _formBuilder: UntypedFormBuilder,
                private cookieService: CookieService,) {
                    this.step0 = this._formBuilder.group({
                        enrollcem: ['', Validators.required],
                        enrollsms: ['', Validators.required],
                        mobileNumber: ['', Validators.required],
                        mobileNumberConfirm: ['', Validators.required],
                        pname: ['', Validators.required]},
                        {validators: NumberMustMatch('mobileNumber', 'mobileNumberConfirm')
                    })
    }
    close(): void {
        this.dialogRef.close();
    }
    ngOnInit(){
        
        this.step0.controls['mobileNumber'].setValidators([Validators.required]);
        this.step0.controls['mobileNumberConfirm'].setValidators([Validators.required]);
        this.step0.controls['pname'].setValidators([Validators.required]);


            
            this.step0.controls['mobileNumber'].updateValueAndValidity();
            this.step0.controls['mobileNumberConfirm'].updateValueAndValidity();
            this.step0.controls['pname'].updateValueAndValidity();
    }
    save(){
        this.loading = true;
        this.enrollcem = this.step0.controls['enrollcem'].value;
        this.enrollsms = this.step0.controls['enrollsms'].value;
        this.mobileNumber = this.step0.controls['mobileNumber'].value;
        this.mobileconfirm = this.step0.controls['mobileNumberConfirm'].value;
        this.pname = this.step0.controls['pname'].value;

        if (this.enrollcem === '1' && this.enrollsms === '1' && this.mobileNumber !== "" && this.mobileconfirm !== "" && this.pname !== "" && this.mobileNumber === this.mobileconfirm)
        {
            console.log(this.enrollcem = 'yes', this.enrollsms = 'yes', this.mobileNumber, this.mobileconfirm, this.pname);
            this.close();
        }
        else
        {
            this.loading = false;
        }
    }
}
