<div class="cem-update-dialog">
  <span>
    <h1 style="font-weight: 500; text-align: center;">Enter CEM Contact Details: </h1>
  </span>
  <b style="font-size: 18px;">What is CEM</b>
  <p style="padding-top: 5px;">
    Enrolling in CEM (Customer Engagement Module) will allow us to send you text reminders of your appointment.
    This service is a personalized customer experience that not only updates you about your appointment, it eliminates
    surprises by providing updates on the inspector's arrival.
  </p>
  <p>
    By participating, you are consenting to receive text messages sent by an automated telephone dialing system.
    <b>Please be advised that standard data and message rates may apply.</b>
  </p>
  <div [formGroup] = "step0">
  <div>
    <mat-label style="font-weight: 600;">Enrolled in CEM:* </mat-label>
    <mat-radio-group style="padding:10px" aria-label="Select an option" formControlName="enrollcem">
      <mat-radio-button value="1">Yes</mat-radio-button>
      <mat-radio-button style="padding:10px" value="2" [checked]="true">No</mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <mat-label style="font-weight: 600;">Agree to Receive SMS Messages:* </mat-label>
    <mat-radio-group style="padding:10px" aria-label="Select an option" formControlName="enrollsms">
      <mat-radio-button value="1">Yes</mat-radio-button>
      <mat-radio-button style="padding:10px" value="2" [checked]="true">No</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="col-md-4 ">
    <mat-form-field>
      <mat-label>Mobile Number*</mat-label>
      <input matInput placeholder="(123) 456-7890" formControlName="mobileNumber"
             mask="(000) 000-0000">
      <mat-error
          *ngIf="step0.controls['mobileNumber'].invalid && (step0.controls['mobileNumber'].dirty || step0.controls['mobileNumber'].touched)">

          <div *ngIf="step0.controls['mobileNumber'].errors?.required">
              Mobile Number is required.
          </div>
      </mat-error>
  </mat-form-field>
  </div>

  <div class="col-md-4 ">
    <mat-form-field>
      <mat-label>Confirm Mobile Number*</mat-label>
      <input matInput placeholder="(123) 456-7890" formControlName="mobileNumberConfirm"
             mask="(000) 000-0000">

      <mat-error
          *ngIf="step0.controls['mobileNumberConfirm'].invalid && (step0.controls['mobileNumberConfirm'].dirty || step0.controls['mobileNumberConfirm'].touched) && step0.controls['mobileNumber'].value !== step0.controls['mobileNumberConfirm'].value">
          Mobile Number does not match.
      </mat-error>
  </mat-form-field>
  </div>
  <div class="col-md-4 ">
    <mat-form-field>
      <mat-label>Name of Person Filling out this Form*</mat-label>
      <input matInput placeholder="Name of Person Filling out this Form" formControlName="pname">
      <mat-error
          *ngIf="step0.controls['pname'].invalid && (step0.controls['pname'].dirty || step0.controls['pname'].touched)">
          <div *ngIf="step0.controls['pname'].errors?.required">
              Person name is required.
          </div>
      </mat-error>
    </mat-form-field>
  </div>
</div>
  <p style="padding-top: 5px;">
    By entering my name above I am certifying that the above is correct to the best of my knowledge / ability and I
    have the proper rights or permissions to initiate messaging to the provided mobile number.
  </p>
  <div class="text-center mt-4">
    <button mat-raised-button class="btn btn-success btn-lg text-white fw-bold" [disabled] = "loading" (click)="save()" name="SAVE">SAVE</button>
  </div>
</div>