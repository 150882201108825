import {Component, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import {DialogLogin} from "../app.component";
import {UntypedFormControl} from "@angular/forms";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {AppComponent} from "../app.component";
import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
    floatLabelControl = new UntypedFormControl('auto');
    username = "";
    password = "";

    constructor(public dialog: MatDialog, public router: Router,
                private cookieService: CookieService) {
    }

    ngOnInit(): void {
    }

    loginForm() {
        var loginData = this.cookieService.get('True360');

        if (loginData) {
            this.router.navigate(['dealer', 'schedule-an-appointment'])
        } else {
            this.dialog.open(DialogLogin, {
                data: {floatLabelControl: this.floatLabelControl, username: this.username, password: this.password},
            });
        }
    }
}
