<div class="hero-section">
    <div class="full-width ">
        <h1>Lease<br>Holders</h1>

        <div class="car"></div>
        <div class="triangle"></div>
    </div>
</div>

<div class="info-section lease-holders">
    <div class="container">
        <h3>It's Time to Turn in Your Lease</h3>

        <p class="subheading">The next steps are easier than you think</p>

        <p>If it’s time for you to turn in your leased vehicle, it’s time for you to get an inspection. The good news is
            that we make it easy, transparent and convenient.</p>

        <div class="row d-flex justify-content-center flex-sm-column flex-md-row text-center align-items-md-stretch"
             style="margin: 80px 0">
            <mat-card class="col-11 col-md-11 col-lg-3 bg-body rounded shadow-lg mx-sm-auto mx-md-0">
                <h4 class="fw-bolder fs-5 text-primary pt-3">INSPECTION<br>IS FREE</h4>

                <p>Our inspections are complimentary and give you the insights you need to make informed decisions about
                    your lease turn-in.</p>
            </mat-card>
            <mat-card
                class="col-11 col-md-11 col-lg-3 mx-md-4 bg-body rounded shadow-lg my-sm-4 my-4 my-lg-0 mx-sm-auto mx-md-0">
                <h4 class="fw-bolder fs-5 text-primary pt-3">YOU PICK A<br>TIME SLOT</h4>

                <p>Select the date and time frame that best meets your schedule. Inspections only take about 30 to 45
                    minutes.</p>
            </mat-card>
            <mat-card class="col-11 col-md-11 col-lg-3 bg-body rounded shadow-lg mx-sm-auto mx-md-0">
                <h4 class="fw-bolder fs-5 text-primary pt-3">CHOOSE YOUR HOME, WORK OR OTHER CONVENIENT LOCATION</h4>

                <p>Need to have your inspection during your lunch break? Running errands and want your vehicle inspected
                    while you’re already out? No problem!</p>
            </mat-card>
        </div>
    </div>
</div>

<div class="inspection-ways">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h3 class="section-heading text-sm-center text-md-start">Two Ways to Get an Inspection</h3>
            </div>
            <div class="col-md-6">
                <div class="white-box call">
                    <h4>Call to Schedule</h4>

                    <p class="mb-4">Our friendly representatives are based in the U.S. and available from 8 a.m. to 6:30 p.m. EST.</p>

                    <a class="btn btn-success btn-lg text-white fw-bold" href="tel:18003404080">Call Now</a>
                </div>

                <div class="white-box schedule">
                    <h4>Schedule Online</h4>

                    <p class="mb-4">View our inspectors’ schedules and request the best time. You’ll receive
                        confirmation and reminders. How? Via email, or text.</p>

                    <a class="btn btn-success btn-lg text-white fw-bold" href="/off-lease/schedule-an-appointment">Schedule</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="questions">
    <div class="container-fluid p-0">
        <div class="question1">
            <div class="container">
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-5">
                        <h3 class="section-heading">Why Do I Need an Inspection?</h3>

                        <p>When you lease a vehicle, it should be returned in the best possible condition. Normal wear
                            and tear is expected. Your vehicle could have damage that is more than normal wear and
                            tear.</p>

                        <p>An inspection is designed to give you information about your vehicle condition so that you
                            can make decisions. If the inspection indicates that you do have excess damage, you can talk
                            to your lease company about whether you should do repairs or whether those will be handled
                            at turn-in.</p>
                    </div>
                    <div class="col-md-1"></div>
                </div>
            </div>
        </div>

        <div class="question2">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="section-heading">Know Before You Turn In</h3>

                        <p>The inspection is all about information — for you and for the dealership. No one likes
                            surprises when it comes to your vehicle.</p>

                        <p class="emphasis">Estimate your cost and plan ahead</p>

                        <p>We provide you with enough information so that you know how much you’ll owe at the end of
                            your lease. You can avoid an unexpected bill and make informed decisions about your vehicle
                            and the associated costs.</p>
                    </div>
                </div>
                <div class="col-md-6"></div>
            </div>
        </div>
    </div>
</div>

<div class="about-inspection">
    <div class="container">
        <h3>About Your Inspection</h3>

        <div class="row">
            <div class="col-md-6">
                <ul class="column-1">
                    <li><span>1.</span>Schedule your appointment. You’ll receive reminders and notifications leading up
                        to your inspection.
                    </li>

                    <li><span>2.</span>The inspector will introduce him or herself. All True360 inspectors have a photo
                        ID badge. The inspection will take 30 to 45 minutes. Have your keys ready. There isn’t a test
                        drive, but the inspector will start the vehicle to test electrical systems.
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <ul class="column-2">
                    <li><span>3.</span>The inspector will provide you with an inspection report ― sign the report to
                        acknowledge that you received it. Your signature does not mean you agree with the inspection.
                        You are not automatically bound to or liable for any costs associated with damage described in
                        the report.
                    </li>

                    <li><span>4.</span>We will submit your report to your lease provider within 24 hours of your
                        inspection. The inspector doesn’t have details about your lease agreement. If you have questions
                        about what the inspection report will mean for your turn-in costs, call your leasing company.
                    </li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center">
                <a class="btn btn-success btn-lg text-white fw-bold" href="/off-lease/schedule-an-appointment">Schedule
                    Online</a>
            </div>
        </div>

        <div class="row what-we-inspect">
            <div class="col-md-6">
                <div class="imgsuv">
                <img src="../../assets/trans-exterior.png">
            </div>
            </div>
            <div class="col-md-6">
                <h4>What we inspect</h4>

                <div class="row">
                    <div class="col-md-6 inspection exterior">
                        Exterior
                    </div>
                    <div class="col-md-6 inspection interior">
                        Interior
                    </div>
                    <div class="col-md-6 inspection structure">
                        Structure
                    </div>
                    <div class="col-md-6 inspection engine">
                        Engine & Mechanicals
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
