import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { CookieService } from "ngx-cookie-service";
import { OutstandingDialog } from "../outstanding-dialog/outstanding-dialog.component";
import { ResumeSchedulingComponent } from "../resume-scheduling-dialog/resume-scheduling-dialog.component";
@Component({
    selector: 'app-root',
    templateUrl: 'acura-survey-dialog.html',
})
export class AcuraSurveyDialogComponent{

    step02 : UntypedFormGroup;
    loading = false;
    windshield : any;
    damage: any;
    mileage : any;
    Message : boolean = false;
    selecterror : boolean = false;
    constructor(public dialogRef: MatDialogRef<AcuraSurveyDialogComponent>,
        private _formbuilder: UntypedFormBuilder,
        private cookiservice: CookieService,
        public dialog: MatDialog,
    ){
        this.step02 = this._formbuilder.group({
            windshield : ['', Validators.required],
            damage : ['', Validators.required],
            mileage : ['', Validators.required],
        })
    }
    close(): void {
        this.dialogRef.close();
    }
    ngOnInit(){
        this.step02.controls['mileage'].setValidators([Validators.required]);
        this.damage = this.step02.controls['damage'].setValidators([Validators.required]);
        this.windshield = this.step02.controls['windshield'].setValidators([Validators.required]);

        this.step02.controls['mileage'].updateValueAndValidity();
        this.step02.controls['damage'].updateValueAndValidity();
        this.step02.controls['windshield'].updateValueAndValidity();
    }
    Submit(){

        this.mileage = this.step02.controls['mileage'].value;
        this.damage = this.step02.controls['damage'].value;
        this.windshield = this.step02.controls['windshield'].value;
        
        if (this.mileage === '1' || this.damage === '1' || this.windshield === '1')
        {
            this.close();
            this.openresumedialog();
        }
        else if (this.mileage === "" || this.damage === "" || this.windshield === "")
        {
            this.selecterror = true;
        }
        else{
            this.openoutstandingdialog();
        }
    }
    Onchange()
    {
        this.windshield = this.step02.controls['windshield'].value;
        if (this.windshield === '1')
        {
            this.selecterror = false;
            this.Message = true;
        }
        else{
            this.Message = false;
            }

    }
    removeerror()
    {
        this.mileage = this.step02.controls['mileage'].value;
        if (this.mileage === '1' || this.mileage === '2')
        {
            this.selecterror = false;
        }
    }
    openoutstandingdialog()
    {
        const dialog = this.dialog.open(OutstandingDialog, {
            width:'auto'
        });
    }
    openresumedialog()
    {
        const dialog = this.dialog.open(ResumeSchedulingComponent, {
            width: 'auto'
        });
    }

}
