import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../environments/environment";
//import { toBase64String } from '@angular/compiler/src/output/source_map';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    get api_url(): string {
        return this._api_url;
    }

    set api_url(value: string) {
        this._api_url = value;
    }

    private _api_url = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            //'Authorization': 'Basic ' + environment.authorization_key
            // 'Accept': '*/*',
            // 'Connection': 'keep-alive',
        })
    };

    constructor(public http: HttpClient) {
        this.api_url = environment.api_url;
    }

    buildQueryString(params: any){
        const ret:string[] = [];
        for (let d in params)
            {
                ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(params[d])}`);
            }
        return ret.join('&');
    }

    get(url: string, params: {} = {}): Observable<any> {
        params = this.prepareParams(params);
        return this.http.get(`${url}?${this.buildQueryString(params)}`, this.httpOptions).pipe(
            map(response => this.cleanData(response)),
        );
    }

    post(url: string, params?: {}): Observable<any> {
        params = this.prepareParams(params);
        return this.http.post(url, params, this.httpOptions).pipe(
            map(response => this.cleanData(response)),
        );
    }

    prepareParams(params: {} = {}) {
        // for (const param in params) {
        //     let val = params[param];
        //     let format = 'YYYY-MM-DD';
        //     if (val instanceof Date || moment.isMoment(val)) {
        //         if (val instanceof Date) {
        //             val = moment(val);
        //         }
        //         if (val.hours() || val.minutes() || val.seconds()) {
        //             format = 'YYYY-MM-DDTHH:mm:ss.SSSSSS';
        //         }
        //         params[param] = val.format(format);
        //         continue;
        //     }
        //     if (typeof (val) === 'object') {
        //         this.prepareParams(val);
        //     }
        // }
        return params;
    }

    cleanData(response: any) {
        // let data;
        // if (!response) {
        //     return;
        // }
        // if (response['results']) {
        //     data = response['results'];
        // } else {
        //     data = [response];
        // }
        // if (data) {
        //     data.forEach(res => {
        //         for (const key in res) {
        //             if (!res[key] || moment.isMoment(res[key])) {
        //                 continue;
        //             }
        //             const val = res[key];
        //             if (moment(val, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ', true).isValid()) {
        //                 res[key] = moment(val);
        //                 continue;
        //             }
        //             if (moment(val, 'YYYY-MM-DD', true).isValid()) {
        //                 res[key] = moment(val);
        //                 continue;
        //             }
        //             if (moment(val, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()) {
        //                 res[key] = moment(val);
        //                 continue;
        //             }
        //             if (moment(val, 'HH:mm:ss', true).isValid()) {
        //                 res[key] = moment(`${val}`, 'HH:mm:ss');
        //                 continue;
        //             }
        //
        //             if (typeof (val) === 'object') {
        //                 this.cleanData(val);
        //             }
        //         }
        //     });
        // }
        return response;
    }

}
