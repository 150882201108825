import {Component} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {Router} from "@angular/router";
import { FinalThankyouDialog } from "../Thank-you-very-much-dialog/final-dialog.component";

@Component({
    selector: 'app-root',
    templateUrl: 'outstanding-dialog.html'
})
export class OutstandingDialog {
    loading = false;
    step03 : UntypedFormGroup;
    proceed : any;
    selecterror : boolean = false;
    constructor(public dialogRef: MatDialogRef<OutstandingDialog>,
        private _formBuilder : UntypedFormBuilder,
        private router : Router,
        public dialog: MatDialog,) {
        this.step03 = this._formBuilder.group({
            proceed : ['', Validators.required],
        })
    }

    close(): void {
        this.dialogRef.close();
    }
    Submit()
    {
        this.proceed = this.step03.controls['proceed'].value;
        if (this.proceed === '1')
        {
            this.dialog.closeAll();
        }
        else if (this.proceed === "")
        {
            this.selecterror = true;
        }
        else
        {
            this.openfinalthankyoudialog();
        }
    }
    onchange()
    {
        this.proceed = this.step03.controls['proceed'].value;
        if (this.proceed === '1' || this.proceed === '2')
        {
            this.selecterror = false;
        }   
    }
    openfinalthankyoudialog()
    {
        const dialog = this.dialog.open(FinalThankyouDialog, {
            width:'auto'
        });
    }
}