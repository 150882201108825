import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";


@Component({
    selector: 'app-root',
    templateUrl: 'FAQ_dialog.html',
})
export class ACVAnywhere_FAQ_Dialog_Component{
    constructor(public dialogRef: MatDialogRef<ACVAnywhere_FAQ_Dialog_Component>){}

    close(): void {
        this.dialogRef.close();
    }
}
