<div class="honda-survey-dialog">
    <div class="main1" style="text-align: center; font-size: 30px; font-weight: 800;">Honda Financial Services</div>
    <span style="padding: 10px;">
        <p>
            We may be able to save you some time and make the return process easier. We ask you three simple questions. Based on your answers, we can pre-qualify you to return your vehicle without scheduling a pre-termination inspection.
        </p>
        <p style="font-weight: 600;">
Please understand, if you decide to return your  vehicle without a pre-termination inspection, even if pre-qualified it may still be inspected at the dealership after you return it, and you may be charged for any wear and tear damage that exceeds your allowance.
        </p>
    </span>

<div [formGroup] = "step01">
    <div>
        <mat-label>1. Is the current mileage on the vehicle over 45,000 miles?:* </mat-label>
    <mat-radio-group (change)="removeerror()" style="padding:10px" aria-label="Select an option" formControlName="mileage" aria-required="true">
      <br><mat-radio-button style="padding:10px" value="1">Yes</mat-radio-button><br>
      <mat-radio-button style="padding:10px" value="2">No</mat-radio-button>
    </mat-radio-group>
    </div>
    <div>
        <mat-label>2. Do you think there is any damage on the vehicle that may exceed your <b>$500</b> excess wear and tear damage allowance provided by Honda Financial Services?:* </mat-label>
    <mat-radio-group style="padding:10px" aria-label="Select an option" formControlName="damage" aria-required="true">
        <br><mat-radio-button style="padding:10px" value="1">Yes</mat-radio-button><br>
      <mat-radio-button style="padding:10px" value="2">No</mat-radio-button>
    </mat-radio-group>
    </div>
    <div>
        <mat-label>3. Is the windshield damaged?:* </mat-label>
    <mat-radio-group (change)="Onchange()" style="padding:10px" aria-label="Select an option" formControlName="windshield" aria-required="true">
        <br><mat-radio-button style="padding:10px" value="1">Yes
      </mat-radio-button><br>
      <mat-radio-button style="padding:10px" value="2">No</mat-radio-button>
    </mat-radio-group>
    </div>
    <div *ngIf="Message">
        <mat-error>
            <div>Note:  Honda recommends you have your windshield replaced before you return your vehicle. If not, you will be charged for a replacement if returned damaged.</div>
        </mat-error>
    </div>
    <div *ngIf="selecterror">
        <mat-error>
            <div>Please select an option. * Required</div>
        </mat-error>
    </div>
    <div class="text-center mt-4">
        <button mat-raised-button class="btn btn-success btn-lg text-white fw-bold" [disabled] = "loading" (click)="Submit()" name="Submit">Submit</button>
      </div>
</div>
</div>
<style>
    .honda-survey-dialog {
        overflow-y: auto;
    }
    .main1 {

        line-height: 30px;
        margin-top: 10px;
    }
</style>