export class ReserveSaRequest {
    Appointment_CreateUser: string = 'CSchedulerUser';
    Appointment_EndTime: string = '';
    Appointment_StartTime: string = '';
    AppointmentType: null = null;
    Assignment_Date: string = '';
    AssignmentId: string | number = 0;
    City: string = '';
    Color: null = null;
    ContactEmail: string = '';
    ContactName: string = '';
    ContactPhone: string = '';
    Country: string = 'US';
    CustomerName: string = '';
    Exist: boolean = false;
    Forced: boolean = false;
    LesseeName: null = null;
    LOB: string = 'OL';
    Make: null = null;
    MaturityDate: string = '';
    Model: null = null;
    Series: null = null;
    State: string = '';
    Street: string = '';
    VIN: string = '';
    WorkType: string = '';
    Zipcode: number = 0;
}
