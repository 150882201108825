<div class="dialog-warning">
    <div class="close-btn">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close (click)="close()">
            X
        </button>
    </div>
    <p style="margin-top: 10px; font-weight: 500;">If you are scheduling in the state of Wisconsin or New Hampshire please
         select a date within the last 15 days from your Maturity Date.</p>
</div>
<style>
    .close-btn button{
    background-color: #00A878;
    border-radius: 50%;
    padding: 1px 9px;
    font-size: 13px;
    color: #fff;
    border: 0;
    font-weight: 500;
}
.close-btn{
    text-align: right;
}
</style>