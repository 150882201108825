<div class="hero-section">
    <div class="container position-relative">
        <h1>Convenient.<br>Accurate.<br>Courteous.</h1>

        <p>Vehicle inspections that give you peace of mind.</p>

        <div class="car-group"></div>
        <div class="triangle"></div>
    </div>
</div>

<div class="home-info-section">
    <div class="container">
        <div class="info-block">
            <p>True360 is dedicated to performing comprehensive vehicle inspections. Our certified team performs inspections for the top automotive companies in the world and for individuals just like you.</p>
        </div>

        <div class="row d-flex justify-content-center flex-sm-column flex-md-row text-center align-items-md-stretch" style="margin: 80px 0">
            <mat-card class="col-11 col-md-10 col-lg-3 bg-body rounded shadow-lg mx-sm-auto mx-md-0" >
                <img src="../../assets/car1.png" class="img-fluid" alt="Car" style="margin: -70px 0 0 0;">
                <h3>I need to turn in my lease</h3>
                <p class="fw-semi-bold fs-5">Convenient & fair inspections before turning in your leased vehicle</p>
                <a class="btn btn-success btn-lg text-white fw-bold mt-3" href="/lease-holders">Learn More</a>
            </mat-card>
            <mat-card class="col-11 col-md-10 col-lg-3 mx-md-4 bg-body rounded shadow-lg my-sm-4 my-4 my-lg-0 mx-sm-auto mx-md-0">
                <img src="../../assets/car2.png" alt="Car" class="img-fluid" style="margin: -70px 0 0 0;">
                <h3>I need to help my customer turn in their lease</h3>
                <p class="fw-semi-bold fs-5">Arrange an inspection for a customer of their leased vehicle before turn-in</p>
                <a class="btn btn-success btn-lg text-white fw-bold mt-3" href="/off-lease/schedule-an-appointment">Schedule</a>
            </mat-card>
            <mat-card class="col-11 col-md-10 col-lg-3 bg-body rounded shadow-lg mx-sm-auto mx-md-0">
                <img src="../../assets/car3.png" alt="Car" class="img-fluid" style="margin: -70px 0 0 0;">
                <h3>I need an inspection for an appraisal</h3>
                <p class="fw-semi-bold fs-5">Unbiased third-party inspections for used vehicles at the customer’s location</p>
                <button class="btn btn-success btn-lg text-white fw-bold" style="margin: 20px 0 0;" (click)="loginForm()">Login</button>
            </mat-card>
        </div>
    </div>
</div>
