<div class="FAQ-ACVAnywhere-dialog">
    <div class="main" style="padding-left: 70px; padding-right: 70px;">
    <h3 class="mtop" style="font-size: 25px; margin-top: 25px;"><b>ACV</b> <b style="color: rgb(255, 102, 0);">  ANY<b style="color: rgb(255, 85, 0);">WHERE</b></b>
    </h3><br><br><br>

    <h2 style="font-size: 28px;"><b>ACV ANYWHERE</b></h2><br>
    <div class="responsive">
    <h1 style="color: rgb(255, 102, 0);"><b>GENERAL IN<b style="color: rgb(255, 90, 0);">STRUC</b><b style="color: rgb(255, 75, 0);">TIONS</b></b></h1>
    <br><br><br></div>

    <h3 style="font-size: 24px;">When scheduling an ACV Anywhere inspection, please communicate the following expectations with your 
        customers.</h3><br>

    <div class="line"></div>
    <br><br>
    <div class="VP">
        <h1><b>VEHICLE PREPARATION :</b></h1><br>
        <p>Prior to the inspector’s arrival, please ensure the following preparations have been made.</p><br>
        <p><b>1.</b> The vehicle must be in a well-lit, easily accessible location. Inspections cannot be performed in parking or 
            residential garages. Inspectors are not permitted to move a vehicle out of a  garage.</p><br>
        <p><b>2.</b> The vehicle must be clean and free of any and all personal property. The inspection will be stopped immediately 
            if the vehicle is found to have personal items present.</p><br>
        <p><b>3.</b> If snow is present, the vehicle must be cleared of all ice and snow prior to the inspector’s arrival.</p><br>
        <p><b>4.</b> The vehicle's keys, owner’s manual, and proof of valid registration must be presented.</p><br>
    </div><br><br>
    <div>
        <h1><b>THE INSPECTION :</b></h1><br>
        <p>Both the interior and exterior of the vehicle will be inspected. Our inspections follow a step-by-step comprehensive 
            review process designed to meet the highest industry standards. The inspection will include     use of ACV’s Virtual 
            Lift and Audio Motor Profile technology as described below as well as a vehicle test drive.</p><br>
        <p><b>Virtual Lift</b> - The Virtual Lift is a portable, drive-over solution utilizing mobile device technology that is 
            operated by a single inspector. Within minutes, the Virtual Lift creates a detailed image of the undercarriage   
            that is included as part of the condition report.</p><br>
        <p><b>Audio Motor Profile</b> - Includes a recording of the vehicle’s engine which is then included with the condition 
            report so the Dealer can listen to the mechanics of the vehicle to help with your vehicle assessment.</p><br>
        <p><b>Test Drive</b> - The test drive is performed on local roads for a short distance of no more than 2 miles.</p>
    </div><br><br>
    <div>
        <p>*The test drive is done to assess the performance of the vehicle during normal driving conditions. If it is not safe 
            to drive the vehicle due to its condition, or if the vehicle is not in a safe location to perform one or more    
            of these inspection types, the inspection will not be completed and will need to be rescheduled</p>
    </div><br><br>
    <div>
            <img src="../../assets/ACV.jpg" class="img-fluid"  style="margin: -70px 0 0 0; height: 100px; margin-top: 20px;">
    </div>
    <div class="text-center mt-4" style="padding-top:20px ; padding-bottom: 20px;">
        <button mat-raised-button class="btn btn-success btn-lg text-white fw-bold" (click)="close()">CLOSE</button>
      </div>
</div>
<footer style="width: 200%; background-color: rgb(255, 85, 0); height: 10px; padding-top: 10px; ">
</footer>
</div>
    <style>
        p {
            font-size: 17px;
        }
            @media screen and (min-width: 601px) 
            {
              .responsive h1
              {
               font-size: 70px;
               line-height: 64px;
              }
            }
            @media screen and (max-width: 600px) 
            {
                .responsive h1
                 {
                   font-size: 37px;
                 }
                 .VP h1{
                    font-size: 23px;
                 }
                 .mtop{
                    margin-top: 70px;
                 }
            }
            .line
            {
                width: 100%;
                border: 1px solid black;
            }
    </style>