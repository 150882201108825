<div class="container">
    <div class="row mb-4">
        <div class="col-md-12">
            <h2>{{ title }}</h2>
        </div>
        <div class="col-md-12" [hidden]="currentStep >= 8">
            <mat-stepper orientation="vertical" #stepper linear>
                <mat-step [completed]="completed" [editable]="editable">
                    <form [formGroup]="step1">
                        <ng-template matStepLabel>About the Vehicle</ng-template>
                        <div *ngIf="userType === 'consumer'">
                            <div class="row">
                                <div class="col-md-12">
                                    <div *ngIf="!vinNotFound">
                                        <p>True360 welcomes you to schedule your end of lease vehicle inspection
                                            appointment. At any time during the scheduling process, you can call the
                                            True360 National Customer Service Center to speak with our team.</p>

                                        <p>Please enter your account number as it appears on your monthly statement and
                                            the full 17-character VIN of your vehicle below to start the process.</p>
                                    </div>

                                    <div *ngIf="vinNotFound">
                                        <p class="text-danger">We are unable to find that VIN and account number
                                            combination in our system.
                                            Please confirm the numbers or correct the issue and try again.</p>

                                        <p>If you need an inspection for your vehicle nearing the end of its lease,
                                            confirm with your lease company that True360 is the right service.</p>

                                        <p>If you need to schedule an inspection as part of an appraisal, login as a
                                            dealer to get started.</p>

                                        <p>You can also contact our Customer Service Center at 1-800-340-4080, Monday
                                            through Friday, 8 a.m. to 6:30 p.m. EST, and a representative will be happy
                                            to assist you.</p>
                                    </div>
                                    <div *ngIf="maturityDateIsOver">
                                        <p class="text-danger">The finance company requests an inspection be scheduled
                                            prior to the lease maturity date. Please reach out directly to them for
                                            further instructions.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 ">
                                    <mat-form-field class="">
                                        <mat-label>VIN</mat-label>
                                        <input matInput placeholder="12345678901234567" formControlName="vin">
                                        <button matSuffix (click)="openDialog()" class="help-btn">
                                            <span>?</span>
                                        </button>
                                        <mat-hint
                                            *ngIf="!step1.controls['vin'].dirty && !step1.controls['vin'].touched">All
                                            17 Characters
                                        </mat-hint>
                                        <mat-error
                                            *ngIf="step1.controls['vin'].invalid && (step1.controls['vin'].dirty || step1.controls['vin'].touched)">
                                            <div *ngIf="step1.controls['vin'].errors?.required">
                                                VIN is required.
                                            </div>
                                            <div
                                                *ngIf="step1.controls['vin'].errors?.minlength || step1.controls['vin'].errors?.maxlength">
                                                VIN must be 17 characters long.
                                            </div>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 ">
                                    <mat-form-field>
                                        <mat-label>Account Number</mat-label>
                                        <input matInput placeholder="12345678901234567"
                                               formControlName="accountNumber">
                                        <mat-hint
                                            *ngIf="!step1.controls['accountNumber'].dirty && !step1.controls['accountNumber'].touched">
                                            As appears on monthly statement
                                        </mat-hint>
                                        <mat-error
                                            *ngIf="step1.controls['accountNumber'].invalid && (step1.controls['accountNumber'].dirty || step1.controls['accountNumber'].touched)">

                                            <div *ngIf="step1.controls['accountNumber'].errors?.required">
                                                Account Number is required.
                                            </div>
                                            <div *ngIf="step1.controls['accountNumber'].errors?.pattern">
                                                Account Number must only contain numbers [0-9].
                                            </div>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 mt-4">
                                    <div class="alert alert-danger" *ngIf="step1Error">{{ step1Error }}</div>
                                    <button class="btn btn-success btn-lg text-white fw-bold"
                                            [disabled]="loading"
                                            (click)="searchVIN()">
                                        <span class="spinner-border spinner-border-sm text-white align-baseline"
                                              role="status" aria-hidden="true"
                                              *ngIf="loading"></span>
                                        {{ !loading ? "Enter Inspection Location" : 'Submitting...'}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="userType === 'dealer'">
                            <p class="mt-3">If you need an inspection for an appraisal, please provide the following
                                information to start the process. <a class="text-decoration-underline"
                                                                     routerLink="/off-lease/schedule-an-appointment">Click
                                    Here</a> if you are scheduling an off-lease inspection on behalf of a lessee.</p>

                            <div class="row">
                                <div class="col-md-6 ">
                                    <mat-form-field>
                                        <mat-label>VIN</mat-label>
                                        <input matInput placeholder="12345678901234567" formControlName="vin">
                                        <mat-hint
                                            *ngIf="!step1.controls['vin'].dirty && !step1.controls['vin'].touched">All
                                            17 Characters
                                        </mat-hint>
                                        <mat-error
                                            *ngIf="step1.controls['vin'].invalid && (step1.controls['vin'].dirty || step1.controls['vin'].touched)">

                                            <div *ngIf="step1.controls['vin'].errors?.required">
                                                VIN is required.
                                            </div>
                                            <div
                                                *ngIf="step1.controls['vin'].errors?.minlength || step1.controls['vin'].errors?.maxlength">
                                                VIN must be 17 characters long.
                                            </div>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 ">
                                    <mat-form-field>
                                        <mat-label>Vehicle Make</mat-label>
                                        <input matInput placeholder="Enter Make" formControlName="make">
                                        <mat-error
                                            *ngIf="step1.controls['make'].invalid && (step1.controls['make'].dirty || step1.controls['make'].touched)">

                                            <div *ngIf="step1.controls['make'].errors?.required">
                                                Make is required.
                                            </div>
                                        </mat-error>
                                    </mat-form-field>

                                </div>
                                <div class="col-md-6 ">
                                    <mat-form-field>
                                        <mat-label>Vehicle Model</mat-label>
                                        <input matInput placeholder="Enter Model" formControlName="model">
                                        <mat-error
                                            *ngIf="step1.controls['model'].invalid && (step1.controls['model'].dirty || step1.controls['model'].touched)">

                                            <div *ngIf="step1.controls['model'].errors?.required">
                                                Model is required.
                                            </div>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6 ">
                                    <mat-form-field>
                                        <mat-label>Vehicle Year</mat-label>
                                        <input type="number" matInput placeholder="Enter Year" formControlName="year">
                                        <mat-error
                                            *ngIf="step1.controls['year'].invalid && (step1.controls['year'].dirty || step1.controls['year'].touched)">

                                            <div *ngIf="step1.controls['year'].errors?.required">
                                                Year is required.
                                            </div>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div class="alert alert-danger" *ngIf="step1Error">{{ step1Error }}</div>
                                    <button class="btn btn-success btn-lg text-white fw-bold"
                                            [disabled]="loading"
                                            (click)="navigate(2, true)">
                                        <span class="spinner-border spinner-border-sm text-white align-baseline"
                                              role="status" aria-hidden="true"
                                              *ngIf="loading"></span>
                                        {{ !loading ? "Enter Inspection Location" : 'Submitting...'}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [completed]="completed" [editable]="editable">
                    <form [formGroup]="step2">
                        <ng-template matStepLabel>Inspection Location</ng-template>
                        <div class="row mt-4">
                            <div class="col-12 ">
                                <mat-form-field>
                                    <mat-label>Street Address 1</mat-label>
                                    <input matInput placeholder="Enter address" formControlName="streetAddress1">
                                    <mat-error
                                        *ngIf="step2.controls['streetAddress1'].invalid && (step2.controls['streetAddress1'].dirty || step2.controls['streetAddress1'].touched)">

                                        <div *ngIf="step2.controls['streetAddress1'].errors?.required">
                                            Street Address 1 is required.
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12 ">
                                <mat-form-field>
                                    <mat-label>Street Address 2</mat-label>
                                    <input matInput placeholder="Enter address" formControlName="streetAddress2">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-4 ">
                                <mat-form-field>
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput placeholder="Enter zip code" formControlName="zip"
                                           (change)="zipchange()">
                                    <mat-error
                                        *ngIf="step2.controls['zip'].invalid && (step2.controls['zip'].dirty || step2.controls['zip'].touched)">

                                        <div *ngIf="step2.controls['zip'].errors?.required">
                                            Zip is required.
                                        </div>
                                        <div *ngIf="step2.controls['zip'].errors?.pattern">
                                            Zip Code must only contain numbers [0-9].
                                        </div>
                                        <div
                                            *ngIf="step2.controls['zip'].errors?.minlength || step2.controls['zip'].errors?.maxlength">
                                            ZIP must be 5 digit number.
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-4 ">
                                <mat-form-field>
                                    <mat-label>City</mat-label>
                                    <mat-select formControlName="city">
                                        <mat-option *ngFor="let city of Cities"
                                                    value="{{city.PostalCity}}">{{city.PostalCity}}</mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="step2.controls['city'].invalid && (step2.controls['city'].dirty || step2.controls['city'].touched)">

                                        <div *ngIf="step2.controls['city'].errors?.required">
                                            City is required.
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12 col-md-4 ">
                                <mat-form-field>
                                    <mat-label>State</mat-label>
                                    <input matInput placeholder="State" formControlName="state" readonly>

                                    <mat-error
                                        *ngIf="step2.controls['state'].invalid && (step2.controls['state'].dirty || step2.controls['state'].touched)">

                                        <div *ngIf="step2.controls['state'].errors?.required">
                                            State is required.
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-12 ">
                                <mat-form-field class="">
                                    <mat-label>Additional Information</mat-label>
                                    <textarea matInput placeholder="Enter information"
                                              formControlName="additionalInfo"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="col-12 ">
                                <mat-form-field class="">
                                    <mat-label>Driving Directions</mat-label>
                                    <textarea matInput placeholder="Nearest cross street or landmark"
                                              formControlName="drivingDirections"></textarea>
                                    <mat-error
                                        *ngIf="step2.controls['drivingDirections'].invalid && (step2.controls['drivingDirections'].dirty || step2.controls['drivingDirections'].touched)">

                                        <div *ngIf="step2.controls['drivingDirections'].errors?.required">
                                            Driving Directions are required.
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 mt-4">
                                <div class="alert alert-danger" *ngIf="step2Error">{{ step2Error }}</div>
                                <button class="btn btn-danger btn-lg text-white fw-bold mr-2" (click)="moveStepper(0)">
                                    Back
                                </button>
                                <button *ngIf="userType === 'consumer'"
                                        class="btn btn-success btn-lg text-white fw-bold" style="margin: 0 0 0 20px;"
                                        [disabled]="loading" (click)="changeWeek('now')">
                                    <span class="spinner-border spinner-border-sm text-white align-baseline"
                                          role="status" aria-hidden="true"
                                          *ngIf="loading"></span>
                                    {{ !loading ? "Next" : 'Loading...'}}
                                </button>

                                <button *ngIf="userType === 'dealer'" class="btn btn-success btn-lg text-white fw-bold"
                                        style="margin: 0 0 0 20px;"
                                        [disabled]="loading" (click)="navigate(3, true)">
                                    <span class="spinner-border spinner-border-sm text-white align-baseline"
                                          role="status" aria-hidden="true"
                                          *ngIf="loading"></span>
                                    {{ !loading ? "Next" : 'Loading...'}}
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step *ngIf="userType ==='dealer'" [completed]="completed" [editable]="editable">
                    <form [formGroup]="step3">
                        <ng-template matStepLabel>About the Vehicle Owner</ng-template>
                        <div class="row mt-4">
                            <div style="margin-bottom: 10px;">
                                <button class="btn btn-success btn-lg text-white fw-bold"
                                        (click)="ArfDialogShow()"
                                        *ngIf="(step2Error || step3Error) && !isArfSubmittedForCurrentVin">Availability Request Form
                                </button>
                            </div>
                            <div class="col-md-6 ">
                                <mat-form-field>
                                    <mat-label>Vehicle Owner's Name</mat-label>
                                    <input matInput placeholder="Enter First and Last Name" formControlName="ownerName">
                                    <mat-error
                                        *ngIf="step3.controls['ownerName'].invalid && (step3.controls['ownerName'].dirty || step3.controls['ownerName'].touched)">

                                        <div *ngIf="step3.controls['ownerName'].errors?.required">
                                            Vehicle Owner's First Name is required.
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6 ">
                                <mat-form-field>
                                    <mat-label>Vehicle Owner's Contact Phone #</mat-label>
                                    <input matInput placeholder="(123) 456-7890" formControlName="ownerPhone"
                                           mask="(000) 000-0000">
                                    <mat-error
                                        *ngIf="step3.controls['ownerPhone'].invalid && (step3.controls['ownerPhone'].dirty || step3.controls['ownerPhone'].touched)">

                                        <div *ngIf="step3.controls['ownerPhone'].errors?.required">
                                            Vehicle Owner's Phone is required.
                                        </div>
                                        <div *ngIf="step3.controls['ownerPhone'].errors?.mask">
                                            Phone Number must match the pattern (000) 000-0000
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 mt-4">
                                <div class="alert alert-danger" *ngIf="step3Error">{{ step3Error }}</div>
                                <button class="btn btn-danger btn-lg text-white fw-bold mr-2" (click)="moveStepper(1)">
                                    Back
                                </button>
                                <button class="btn btn-success btn-lg text-white fw-bold"
                                        *ngIf="!(step2Error || step3Error)"
                                        [disabled]="loading || step2Error || step3Error"
                                        (click)="changeWeek('now')" style="margin: 0 0 0 20px;">
                                    <span class="spinner-border spinner-border-sm text-white align-baseline"
                                          role="status" aria-hidden="true"
                                          *ngIf="loading"></span>
                                    {{ !loading ? "See Availability" : 'Loading...'}}
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [completed]="completed" [editable]="editable">
                    <form [formGroup]="step4">
                        <ng-template matStepLabel>Choose a Time & Date</ng-template>

                        <p>Select the date and time frame that best meet your schedule. Inspections only take about 30
                            to 45 minutes. <strong>Not seeing any available appointments? Please call
                                1-800-340-4080.</strong></p>
                        <div>
                            <p style="color: red;">Please do not refresh or leave this page while available appointment
                                offers are loading.</p>
                        </div>
                        <div *ngIf="validstate && userType === 'consumer'" style="border-style: solid;">
                            <p style="color: #ff7d00; font-size: 17px; margin-left: 8px; margin-top: 8px;">Wisconsin and
                                New Hampshire state regulations require your end of lease inspection to be performed the
                                last 15 days prior to your Maturity Date. Please select an appointment date option
                                within this timeframe. If you have any questions regarding this regulation please
                                contact your lease provider.
                            </p>
                        </div>

                        <p style="margin-top: 8px;" class="alert alert-danger"
                           *ngIf="errorMessage">{{ errorMessage }}</p>

                        <div class="calendar-header">
                            <div class="month" style="padding-top: 15px;">
                                <h4>{{ currentMonth }} {{ currentYear }}</h4>
                            </div>
                            <div class="controls">
                                <button aria-label="See previous weeks"
                                        (click)="changeWeek('previous')">
                                    <mat-icon>chevron_left</mat-icon>
                                </button>
                                <button aria-label="See future weeks" (click)="changeWeek('next')">
                                    <mat-icon>chevron_right</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div [ngClass]="{'loading-week-days': loadingSchedule, 'week-days': !loadingSchedule}">
                            <mat-icon *ngIf="loadingSchedule" class="loading-calendar-icon">
                                <mat-spinner diameter="50"></mat-spinner>
                            </mat-icon>
                            <div class="week-day" *ngFor="let weekDate of currentDates">
                                <p class="day-date">{{ weekDate.name }}</p>

                                <div class="appointment-time"
                                     [ngClass]="{'selected': weekDate.slot1.status === 'selected','unavailable': weekDate.slot1.status === 'unavailable'}"
                                     (click)="selectDay(weekDate, 1)">
                                    8 a.m. to 12:30 p.m.
                                </div>

                                <div class="appointment-time"
                                     [ngClass]="{'selected': weekDate.slot2.status === 'selected','unavailable': weekDate.slot2.status === 'unavailable'}"
                                     (click)="selectDay(weekDate, 2)">
                                    12:30 p.m. to 5 p.m.
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-12 mt-3 pt-2 mb-3">
                                <div class="alert alert-danger" *ngIf="step4Error">{{ step4Error }}</div>
                                <div class="alert alert-success" *ngIf="selectedtime">
                                    <h3><span>Previously selected :</span> {{ selectedTimePeriod }}</h3>
                                </div>
                                <button class="btn btn-danger btn-lg text-white fw-bold mr-2"
                                        *ngIf="userType === 'consumer'" (click)="moveStepper(1)">Back
                                </button>
                                <button class="btn btn-danger btn-lg text-white fw-bold mr-2"
                                        *ngIf="userType === 'dealer'" (click)="moveStepper(2)">Back
                                </button>
                                <button class="btn btn-success btn-lg text-white fw-bold"
                                        [disabled]="!selectedWeekDay || (loading && !loadingSchedule)"
                                        (click)="navigate(5,true)" style="margin: 0 0 0 20px;">
                                    <span class="spinner-border spinner-border-sm text-white align-baseline"
                                          role="status" aria-hidden="true"
                                          *ngIf="loading"></span>
                                    {{ !loading ? "Book It" : 'Booking...'}}
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [completed]="completed" [editable]="editable">
                    <form [formGroup]="step5">
                        <ng-template matStepLabel>Contact Information</ng-template>
                        <p>Please enter the contact information of the person who will be at the appointment so that we
                            can provide updates related to the appointment.</p>

                        <div class="row ">
                            <div class="col-md-12 mt-4 " *ngIf="userType === 'dealer'">
                                <mat-form-field>
                                    <mat-label>Name of the Person Who Will be Present at the Appointment</mat-label>
                                    <input matInput placeholder="Enter name" formControlName="name">
                                    <mat-error
                                        *ngIf="step5.controls['name'].invalid && (step5.controls['name'].dirty || step5.controls['name'].touched)">

                                        <div *ngIf="step5.controls['name'].errors?.required">
                                            Name is required.
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!--<div class="col-md-12" *ngIf="userType === 'dealer'">-->
                            <!--<mat-form-field   class="">-->
                            <!--<mat-label>Customer Name</mat-label>-->
                            <!--<input matInput placeholder="Enter name">-->
                            <!--</mat-form-field>-->
                            <!--</div>-->

                            <div class="col-md-4 ">
                                <mat-form-field>
                                    <mat-label>Mobile Number</mat-label>
                                    <input matInput placeholder="(123) 456-7890" formControlName="mobileNumber"
                                           mask="(000) 000-0000">
                                    <mat-error
                                        *ngIf="step5.controls['mobileNumber'].invalid && (step5.controls['mobileNumber'].dirty || step5.controls['mobileNumber'].touched)">

                                        <div *ngIf="step5.controls['mobileNumber'].errors?.required">
                                            Mobile Number is required.
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 ">
                                <mat-form-field>
                                    <mat-label>Confirm Mobile Number</mat-label>
                                    <input matInput placeholder="(123) 456-7890" formControlName="mobileNumberConfirm"
                                           mask="(000) 000-0000">

                                    <mat-error
                                        *ngIf="step5.controls['mobileNumberConfirm'].invalid && (step5.controls['mobileNumberConfirm'].dirty || step5.controls['mobileNumberConfirm'].touched) && step5.controls['mobileNumber'].value !== step5.controls['mobileNumberConfirm'].value">
                                        Mobile Number does not match.
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4 ">
                                <mat-form-field>
                                    <mat-label>Email Address</mat-label>
                                    <input matInput placeholder="ljohnsmith@email.com" formControlName="email">
                                    <mat-error
                                        *ngIf="step5.controls['email'].invalid && (step5.controls['email'].dirty || step5.controls['email'].touched)">

                                        <div *ngIf="step5.controls['email'].errors?.required">
                                            Email is required.
                                        </div>
                                        <div
                                            *ngIf="step5.controls['email'].errors?.email || step5.controls['email'].errors?.pattern">
                                            Please enter a valid email address
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 " *ngIf="userType === 'consumer'">
                                <mat-form-field>
                                    <mat-label>Name</mat-label>
                                    <input matInput placeholder="Enter name" formControlName="name">
                                    <mat-error
                                        *ngIf="step5.controls['name'].invalid && (step5.controls['name'].dirty || step5.controls['name'].touched)">

                                        <div *ngIf="step5.controls['name'].errors?.required">
                                            Name is required.
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-12 mt-4">
                                <button class="btn btn-danger btn-lg text-white fw-bold mr-2" (click)="moveStepper(2)">
                                    Back
                                </button>
                                <button class="btn btn-success btn-lg text-white fw-bold"
                                        *ngIf="appointAssistanceLinkVisibleForFlorida"
                                        (click)="navigate(6, true)"
                                        style="margin: 0 0 0 20px;">Next
                                </button>
                                <button class="btn btn-success btn-lg text-white fw-bold"
                                        *ngIf="!appointAssistanceLinkVisibleForFlorida"
                                        (click)="navigate(7, true)"
                                        style="margin: 0 0 0 20px;">Next
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step *ngIf="appointAssistanceLinkVisibleForFlorida" [completed]="completed" [editable]="editable">
                    <form [formGroup]="step6">
                        <ng-template matStepLabel>Appointment Assistance</ng-template>
                        <div class="container">
                            <div class="container-fluid">
                                <p style="font-size:25px;color: #004E7D; padding-top: 10px;">What is Appointment
                                    Assistance</p>
                                <p>Enrolling in Appointment Assistance will allow us to send text reminders of upcoming
                                    appointments. This service is a personalized customer experience that eliminates
                                    surprises by providing updates on the inspector's arrival.</p>
                                <p class="space-below">By participating, you are indicating that you have a business
                                    relationship with the consumer, the consumer has expressly requested text reminders
                                    of
                                    upcoming appointments, and you have received prior express written consent from the
                                    consumer to receive text messages sent by an automated telephone dialing system.
                                    <strong>Please be advised that standard data and message rates may apply.</strong>
                                </p>
                            </div>
                            <div class="container-fluid" *ngIf="appAssistSubStep===1">
                                <div class="row" style="padding-bottom: 15px;">
                                    <div class="col-md-6">
                                        <button class="btn btn-danger btn-lg text-white fw-bold"
                                                *ngIf="userType === 'consumer'"
                                                (click)="moveStepper(3,1)">Back
                                        </button>
                                        <button class="btn btn-danger btn-lg text-white fw-bold"
                                                *ngIf="userType === 'dealer'"
                                                (click)="moveStepper(4,1)">Back
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <button class="btn btn-success btn-lg text-white fw-bold"
                                                (click)="navigate(7)">Next
                                        </button>&nbsp;&nbsp;
                                        <button class="btn btn-success btn-lg text-white fw-bold"
                                                (click)="changeSubStep(2)">Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div *ngIf="appAssistSubStep===2">
                                    <p>Enrolled in Appointment Assistance*&nbsp;
                                        <mat-radio-group (change)="enrolledInAppAssistChanged()" style="padding:10px"
                                                         aria-label="Select an option"
                                                         formControlName="enrolledInAppAssist">
                                            <mat-radio-button style="padding:10px" value="1">Yes</mat-radio-button>
                                            <mat-radio-button style="padding:10px" value="0" [checked]="true">No
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </p>
                                    <p>
                                        Agree to Receive SMS Messages*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <mat-radio-group (change)="enrolledInAppAssistChanged()" style="padding:10px"
                                                         aria-label="Select an option" formControlName="agreeToSms">
                                            <mat-radio-button style="padding:10px" value="1">Yes</mat-radio-button>
                                            <mat-radio-button style="padding:10px" value="0" [checked]="true">No
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </p>
                                </div>
                                <div [hidden]="assistHideTextFields || (appAssistSubStep===1)">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <mat-label>First Name</mat-label>
                                                <input matInput placeholder="First Name"
                                                       formControlName="assistFirstName">
                                                <mat-error
                                                    *ngIf="step6.controls['assistFirstName'].invalid &&
                                (step6.controls['assistFirstName'].dirty ||
                                step6.controls['assistFirstName'].touched)">
                                                    <div
                                                        *ngIf="step6.controls['assistFirstName'].errors?.required">
                                                        First Name is required.
                                                    </div>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <mat-label>Last Name</mat-label>
                                                <input matInput placeholder="Last Name"
                                                       formControlName="assistLastName">
                                                <mat-error
                                                    *ngIf="step6.controls['assistLastName'].invalid &&
                                (step6.controls['assistLastName'].dirty ||
                                step6.controls['assistLastName'].touched)">
                                                    <div
                                                        *ngIf="step6.controls['assistLastName'].errors?.required">
                                                        Last Name is required.
                                                    </div>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <mat-label>Mobile Number</mat-label>
                                                <input matInput placeholder="(123) 456-7890" mask="(000) 000-0000"
                                                       formControlName="assistMobileNumber">
                                                <mat-error *ngIf="step6.controls['assistMobileNumber'].invalid &&
                                                (step6.controls['assistMobileNumber'].dirty ||
                                                step6.controls['assistMobileNumber'].touched)">
                                                    <div
                                                        *ngIf="step6.controls['assistMobileNumber'].errors?.required">
                                                        Mobile Number is required.
                                                    </div>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <mat-label>Confirm Mobile Number</mat-label>
                                                <input matInput placeholder="(123) 456-7890" mask="(000) 000-0000"
                                                       formControlName="assistConfirmMobileNumber">
                                                <mat-error *ngIf="step6.controls['assistConfirmMobileNumber'].invalid
                            && (step6.controls['assistConfirmMobileNumber'].dirty ||
                            step6.controls['assistConfirmMobileNumber'].touched) &&
                            step6.controls['assistMobileNumber'].value !== step6.controls['assistConfirmMobileNumber'].value">
                                                    Mobile Number does not match.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <mat-label>Name of Person Filling out this Form</mat-label>
                                                <input matInput placeholder="Name of Person Filling out this Form"
                                                       formControlName="assistNameOfPerson">
                                                <mat-error
                                                    *ngIf="step6.controls['assistNameOfPerson'].invalid &&
                                                    (step6.controls['assistNameOfPerson'].dirty ||
                                                    step6.controls['assistNameOfPerson'].touched)">
                                                    <div
                                                        *ngIf="step6.controls['assistNameOfPerson'].errors?.required">
                                                        Name of Person Filling out this Form is required.
                                                    </div>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <p>By entering the information above, I am certifying that the above is correct to
                                        the best of my knowledge and <strong>I have the rights and permissions necessary
                                            to legally initiate messaging to the provided mobile number.</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid" *ngIf="appAssistSubStep===2">
                            <div class="row" style="padding-bottom: 15px;">
                                <div class="col-md-6">
                                    <button class="btn btn-danger btn-lg text-white fw-bold"
                                            *ngIf="userType === 'consumer'"
                                            (click)="moveStepper(3,1)">Back
                                    </button>
                                    <button class="btn btn-danger btn-lg text-white fw-bold"
                                            *ngIf="userType === 'dealer'"
                                            (click)="moveStepper(4,1)">Back
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-success btn-lg text-white fw-bold"
                                            (click)="navigate(7)">{{assistHideTextFields ? 'Next' : 'Confirm'}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step completed="false" [editable]="editable">
                    <form [formGroup]="step7">
                        <ng-template matStepLabel>Confirmation</ng-template>
                        <p class="space-below">Please review your appointment information below. If correct, please
                            click Confirm. Use the back Button if you need to edit the information.</p>

                        <p class="hover-edit">
                            <strong class="text-primary">Inspection Contact:</strong> {{ step5.controls['name'].value }}
                            <mat-icon (click)="returnTo('contact')">edit</mat-icon>
                        </p>
                        <p class="hover-edit">
                            <strong class="text-primary">Phone
                                #:</strong> {{ step5.controls['mobileNumber'].value | phone }}
                            <mat-icon (click)="returnTo('contact')">edit</mat-icon>
                        </p>
                        <p class="hover-edit space-below">
                            <strong class="text-primary">Email:</strong> {{ step5.controls['email'].value }}
                            <mat-icon (click)="returnTo('contact')">edit</mat-icon>
                        </p>

                        <p class="hover-edit">
                            <strong class="text-primary">Inspection
                                Location:</strong> {{ step2.controls['city'].value }}
                            , {{ step2.controls['state'].value }}
                            <mat-icon (click)="returnTo('address')">edit</mat-icon>
                        </p>
                        <p class="hover-edit">
                            <strong class="text-primary">Inspection
                                Address:</strong> {{ step2.controls['streetAddress1'].value }}<span
                            *ngIf="step2.controls['streetAddress2'].value">, {{step2.controls['streetAddress2'].value }}</span>
                            <mat-icon (click)="returnTo('address')">edit</mat-icon>
                        </p>
                        <p class="hover-edit">
                            <strong class="text-primary">Special
                                Instructions:</strong> {{ step2.controls['additionalInfo'].value }}
                            <mat-icon (click)="returnTo('address')">edit</mat-icon>
                        </p>
                        <p class="hover-edit space-below">
                            <strong class="text-primary">Driving
                                Direction:</strong> {{ step2.controls['drivingDirections'].value }}
                            <mat-icon (click)="returnTo('address')">edit</mat-icon>
                        </p>

                        <p class="hover-edit space-below">
                            <strong class="text-primary">Appointment Date & Time:</strong> {{ selectedTimePeriod }}
                            <mat-icon (click)="returnTo('appointment')">edit</mat-icon>
                        </p>

                        <!--<p><strong class="text-primary">Signed Up for Alerts:</strong> Yes</p>-->
                        <!--<p><strong class="text-primary">Text Message Consent:</strong> Yes</p>-->
                        <p class="hover-edit space-below">
                            <strong class="text-primary">Mobile Phone
                                Number:</strong> {{ step5.controls['mobileNumber'].value | phone }}
                            <mat-icon (click)="returnTo('contact')">edit</mat-icon>
                        </p>

                        <div class="alert alert-danger" *ngIf="step7Error">
                            We had an issue retrieving appointments. Please try again or call <a
                            href="tel:1-800-340-4080">1-800-340-4080</a> to schedule an appointment.
                        </div>

                        <div class="mt-4">
                            <button class="btn btn-danger btn-lg text-white fw-bold"
                                    *ngIf="userType === 'consumer' && appointAssistanceLinkVisibleForFlorida"
                                    (click)="moveStepper(4)">Back
                            </button>
                            <button class="btn btn-danger btn-lg text-white fw-bold"
                                    *ngIf="userType === 'consumer' && !appointAssistanceLinkVisibleForFlorida"
                                    (click)="moveStepper(3)">Back
                            </button>
                            <div style="margin-top: 20px;" *ngIf="userType === 'dealer'">
                                <p>Please <a class="text-decoration-underline" style="cursor: pointer;"
                                             (click)="openFAQdialog()">Click Here</a> to see Vehicle Inspection
                                    Instructions.</p>
                            </div>
                            <button class="btn btn-danger btn-lg text-white fw-bold"
                                    *ngIf="userType === 'dealer' && appointAssistanceLinkVisibleForFlorida"
                                    (click)="moveStepper(5)">Back
                            </button>
                            <button class="btn btn-danger btn-lg text-white fw-bold"
                                    *ngIf="userType === 'dealer' && !appointAssistanceLinkVisibleForFlorida"
                                    (click)="moveStepper(4)">Back
                            </button>
                            <button class="btn btn-success btn-lg text-white fw-bold" style="margin: 0 0 0 20px;"
                                    [disabled]="loading"
                                    (click)="bookTimeSlot()">
                                <span class="spinner-border spinner-border-sm text-white align-baseline" role="status"
                                      aria-hidden="true"
                                      *ngIf="loading"></span>
                                {{ !loading ? "Confirm" : 'Confirming...'}}
                            </button>
                            <br>
                        </div>
                    </form>
                </mat-step>
            </mat-stepper>
        </div>

        <div *ngIf="currentStep === 8" class="scheduled-page">
            <div class="row">
                <div class="col-md-6">
                    <p><span>Confirmation Number:</span> {{ confirmationNumber }}</p>

                    <p><span>Appointment Date & Time:</span> {{ selectedTimePeriod }}</p>

                    <p><span>Inspection Address:</span> {{ step2.controls['streetAddress1'].value }}<span
                        *ngIf="step2.controls['streetAddress2'].value">, {{ step2.controls['streetAddress2'].value }}</span>, {{ step2.controls['city'].value }}
                        , {{ step2.controls['state'].value }} {{ step2.controls['zip'].value }}</p>

                    <p>
                        <span>Vehicle: (Make, Model, Year):</span> {{ step1.controls['make'].value }} {{ step1.controls['model'].value }} {{ step1.controls['year'].value }}
                    </p>

                    <p><span>Contact Information:</span></p>
                    <p><span>Name:</span> {{ step5.controls['name'].value }}</p>
                    <p><span>Phone:</span> {{ step5.controls['mobileNumber'].value | phone }}</p>
                    <p><span>Email:</span> {{ step5.controls['email'].value }}</p>

                    <p>On the day of your appointment, you will receive a phone call from the inspector.</p>

                    <h4>
                        <a class="text-primary" [href]="googleCalLink" target="_blank">Add to Google Calendar</a> &nbsp;&nbsp;&nbsp;
                        <a class="text-primary" [href]="iCalLink" target="_blank">Add to iCal</a> &nbsp;&nbsp;&nbsp; <a
                        class="text-primary" [href]="outlookCalLink" target="_blank">Add to Outlook Calendar</a>&nbsp;&nbsp;&nbsp;
                    </h4>
                </div>

                <div class="col-md-6">
                    <p>If you provided an email address, an appointment confirmation will be sent to the email address
                        provided within 24 hours.</p>

                    <p>If you need to cancel or make changes to your scheduled appointment, please return to
                        schedule.true360.com. Click to schedule an off-lease inspection and begin by entering your VIN &
                        Account Number again.</p>

                    <p>You can also contact our Customer Service Center at 1-800-340-4080, Monday through Friday, 8 a.m.
                        to 6:30 p.m. EST, and a representative will be happy to assist you. Please have your
                        confirmation number, VIN or account number ready when you call.</p>

                    <p>Thank you for using our online service to schedule your inspection appointment. <span
                        *ngIf="userType === 'dealer'">Click Log Out to
                        end this session.</span></p>

                    <button *ngIf="userType === 'dealer'" class="btn btn-success btn-lg text-white fw-bold"
                            (click)="logout()">Logout
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="currentStep === 9" class="booked-page">
            <div class="row">
                <div class="col-md-6">
                    <p>There is an appointment currently scheduled for this VIN and account number.</p>

                    <p>If you need to change the date, time, location or other details of your appointment, click
                        Reschedule.<span
                            *ngIf="userType === 'dealer'"> Click Log Out to keep your current appointment.</span></p>

                    <p>You can also contact our Customer Service Center at 1-800-340-4080, Monday through Friday, 8 a.m.
                        to 6:30 p.m. EST, and a representative will be happy to assist you. Please have your
                        confirmation number, VIN or account number ready when you call.</p>
                </div>
                <div class="col-md-6">
                    <p><span>Confirmation Number:</span> {{ confirmationNumber }}</p>

                    <p><span>Appointment Date & Time:</span> {{ selectedTimePeriod }}</p>

                    <p><span>Inspection Address:</span> {{ step2.controls['streetAddress1'].value }}<span
                        *ngIf="step2.controls['streetAddress2'].value">, {{ step2.controls['streetAddress2'].value }}</span>, {{ step2.controls['city'].value }}
                        , {{ step2.controls['state'].value }} {{ step2.controls['zip'].value }}</p>

                    <p>
                        <span>Vehicle: (Make, Model, Year):</span> {{ step1.controls['make'].value }} {{ step1.controls['model'].value }} {{ step1.controls['year'].value }}
                    </p>

                    <p><span>Contact Information:</span></p>
                    <p><span>Name:</span> {{ step5.controls['name'].value }}</p>
                    <p><span>Phone:</span> {{ step5.controls['mobileNumber'].value | phone }}</p>
                    <p><span>Email:</span> {{ step5.controls['email'].value }}</p>


                    <button class="btn btn-success btn-lg text-white fw-bold" [disabled]="loading"
                            (click)="openConfirmCancellationDialog(true)">
                        <span class="spinner-border spinner-border-sm text-white align-baseline" role="status"
                              aria-hidden="true"
                              *ngIf="loading"></span>
                        {{ !loading ? "Reschedule" : 'Rescheduling...'}}
                    </button>

                    <button class="btn btn-success btn-lg text-white fw-bold" style="margin: 0 0 0 20px;"
                            [disabled]="cancelling"
                            (click)="openConfirmCancellationDialog(false)">
                        <span class="spinner-border spinner-border-sm text-white align-baseline" role="status"
                              aria-hidden="true"
                              *ngIf="cancelling"></span>
                        {{ !cancelling ? "Cancel" : 'Cancelling...'}}
                    </button>
                    <button *ngIf="userType === 'dealer'" class="btn btn-success btn-lg text-white fw-bold"
                            style="margin: 0 0 0 20px;" (click)="logout()">Logout
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<style>

    @media screen and (max-width: 600px) {

        .btn-lg {
            margin: 10px 0 10px 0;
        }
    }
</style>
