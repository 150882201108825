<h1 mat-dialog-title>Login with your ACV credentials</h1>

<p>Need a login? <a href="https://app.acvauctions.com/registration" target="_blank">Register</a> with ACV.</p>

<div mat-dialog-content>
    <p *ngIf="errorMessage" class="alert alert-danger">{{ errorMessage }}</p>
    <mat-form-field appearance="fill" [floatLabel]="data.floatLabelControl.value" class="">
        <mat-label>Username</mat-label>
        <input matInput placeholder="Username" name="username" [(ngModel)]="username">
    </mat-form-field>

    <mat-form-field appearance="fill" [floatLabel]="data.floatLabelControl.value" class="">
        <mat-label>Password</mat-label>
        <input matInput type="password" name="password" placeholder="Password" [(ngModel)]="password">
    </mat-form-field>

    <div class="text-center mt-4">
        <button mat-raised-button class="btn btn-success btn-lg text-white fw-bold" [disabled]="loggingIn" (click)="login()">
            <span class="spinner-border spinner-border-sm text-white align-baseline" role="status" aria-hidden="true"
                  *ngIf="loggingIn"></span>
            {{ !loggingIn ? "Login" : 'Logging In...'}}
        </button>
    </div>
    <div class="text-center mt-4">
        <p class="m-0"><a class="forgot-link" href="https://app.acvauctions.com/forgot" target="_blank">Forgot password?</a></p>
    </div>
</div>
  