import {Component, Inject, OnInit} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CreateArfRequest} from "../../../models/create-arf-request";
import {AcvService} from "../../services/acv.service";
import {CreateArfResponse} from "../../../models/create-arf-response";


@Component({
    selector: 'app-root',
    templateUrl: 'arf-dialog.html',
    styleUrls: ['./arf-dialog.css']
})
export class ArfDialogComponent implements OnInit {
    step: UntypedFormGroup;
    submittingArf: boolean = false;
    submittedArf: boolean = false;
    submitErrorId: number = 0;   // 0 - no error, 1 - general error, 2 - ARF request is already submitted
    arfRequestData: CreateArfRequest;

    constructor(
        private acvService: AcvService,
        public dialogRef: MatDialogRef<ArfDialogComponent>,
        private _formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.step = this._formBuilder.group({
            arfemail: ['', [Validators.required, Validators.email, Validators.pattern("^\\S+@\\S+\\.\\S+$")]],
            arfAdditionalInfo: ['', [Validators.maxLength(256)]],
        });
        this.arfRequestData = new CreateArfRequest();
    }

    ngOnInit(): void {
        // The 'data' keeps the information sent from the calling component
        this.arfRequestData = this.data.dataToRequest;
        this.step.controls.arfemail.setValue(this.arfRequestData.ContactEmail);
        this.step.controls.arfAdditionalInfo.setValue('');
    }

    submitArf() {
        this.step.markAllAsTouched();
        if (this.step.invalid) {
            return;
        }
        this.submittingArf = true;
        this.submittedArf = false;
        this.submitErrorId = 0;
        // all other properties are filled on main form (on the scheduler component)
        this.arfRequestData.ARFNotes = this.step.controls['arfAdditionalInfo'].value.trim(); // dialog window form field
        this.arfRequestData.ContactEmail = this.step.controls['arfemail'].value.trim(); // dialog window form field

        this.acvService.SaveARFData(this.arfRequestData).subscribe({
            next: (data: CreateArfResponse) => {
                if (data.ReturnCode === 0) {
                    this.submittingArf = false;
                    this.submittedArf = true;
                    this.submitErrorId = 0;
                } else {
                    this.submittingArf = false;
                    this.submittedArf = true;
                    this.submitErrorId = 1;
                    if (data.Message === "ARF request is already sent") {
                        this.submitErrorId = 2;
                    }
                }
            },
            error: error => {
                this.submittingArf = false;
                this.submittedArf = true;
                this.submitErrorId = 1;
            }
        });
    }

    closeDialog() {
        let dr: { arfAdditionalInfo: string, arfemail: string, submitted: boolean, vin: string } = {
            arfAdditionalInfo: this.step.controls['arfAdditionalInfo'].value.trim(),
            arfemail: this.step.controls['arfemail'].value,
            submitted: this.submittedArf,
            vin: this.arfRequestData.VIN
        }
        this.dialogRef.close(dr);
    }
}
