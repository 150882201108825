import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent, DialogDealer, DialogLogin} from './app.component';
import {MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS, MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatStepperModule} from "@angular/material/stepper";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {HTTP_INTERCEPTORS,HttpClientModule} from '@angular/common/http';
// import { HttpHeaders } from '@angular/common/http';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {LeaseHoldersComponent} from './lease-holders/lease-holders.component';
import {SchedulerComponent} from './scheduler/scheduler.component';
import {HomePageComponent} from './home-page/home-page.component';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {PhonePipe} from './phone.pipe';
import {NgxMaskModule, IConfig} from 'ngx-mask'
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {ConfirmCancellationDialog} from "./dialogs/confirm-cancellation-dialog/confirm-cancellation-dialog.component";
import {CookieService} from "ngx-cookie-service";
import {AboutVinDialogComponent} from "./dialogs/about-vin-dialog/about-vin-dialog.component";
import {CemUpdateDialogComponent} from './dialogs/cem-update-dialog/cem-update-dialog.component';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {HondaSurveyDialogComponent} from './dialogs/Honda-survey-dialog/hond-survey-dialog.component';
import {AcuraSurveyDialogComponent} from './dialogs/Acura-survey-dialog/acura-survey-dialog.component';
import {OutstandingDialog} from './dialogs/outstanding-dialog/outstanding-dialog.component';
import {FinalThankyouDialog} from './dialogs/Thank-you-very-much-dialog/final-dialog.component';
import {ResumeSchedulingComponent} from './dialogs/resume-scheduling-dialog/resume-scheduling-dialog.component';
import {ACVAnywhere_FAQ_Dialog_Component} from './dialogs/FAQ_ACVAnywhere_dialog/FAQ_dialog.component';
import { HeaderInterceptor } from './header.interceptor';
import {ArfDialogComponent} from "./dialogs/arf-dialog/arf-dialog.component";

@NgModule({
    declarations: [
        AppComponent,
        LeaseHoldersComponent,
        SchedulerComponent,
        HomePageComponent,
        DialogLogin,
        ConfirmCancellationDialog,
        AboutVinDialogComponent,
        PhonePipe,
        DialogDealer,
        CemUpdateDialogComponent,
        HondaSurveyDialogComponent,
        ACVAnywhere_FAQ_Dialog_Component,
        AcuraSurveyDialogComponent,
        OutstandingDialog,
        FinalThankyouDialog,
        ResumeSchedulingComponent,
        ArfDialogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NoopAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatDialogModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatStepperModule,
        MatSelectModule,
        MatCardModule,
        NgxMaskModule.forRoot(),
        MatMenuModule,
        MatRadioModule,
    ],
    providers: [
        CookieService,
        {provide:HTTP_INTERCEPTORS,useClass:HeaderInterceptor,multi:true},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill', floatLabel: 'auto'}}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
