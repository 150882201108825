import {HttpClient, HttpHeaders} from "@angular/common/http";
import {GetDealerStatusResponse} from "../models/get-dealer-status-response";
import {CookieService} from "ngx-cookie-service";

export class Helper {

    private static isEmpty(str: string | null | undefined): boolean {
        if (str) {
            return false
        } else {
            return true
        }
    }

    public static GetDealerIdsFromLoginDetails(dealerLoginInfo: any): number[] {
        let dealerIds = new Array<number>()
        dealerIds.push(dealerLoginInfo.data.dealer_id)
        let dealerships: any = dealerLoginInfo.data.dealerships
        if (dealerships === null) {
            return dealerIds
        }
        if (dealerships.length > 0) {
            dealerships.forEach((dealership: any) => {
                let dealershipDealerId: number = dealership.id
                if (dealerIds.indexOf(dealershipDealerId) < 0) {
                    // add dealer id
                    dealerIds.push(dealershipDealerId)
                }
            })
        }
        return dealerIds
    }

    // this method to be used to serialize the object (or array of objects) to
    // string (before saving to cookie)
    public static SerializeToString(obj: any): string {
        return JSON.stringify(obj)
    }

    // this method to be used to serialize the object (or array of objects) to
    // string (before saving to cookie)
    public static DeserializeFromString(cookieString: string): any {
        return JSON.parse(cookieString)
    }

    public static DealersStatusGet(url: string,
                                   dealerIds: number[],
                                   httpOptions: { headers: HttpHeaders },
                                   http: HttpClient): Promise<GetDealerStatusResponse> {
        return http.post<any>(url, dealerIds, httpOptions).toPromise()
    }

    // JIRA SCH-255
    // when I log in to SWISS selecting the drop-down box to select the dealer,
    // any dealer that is deactivated need to show on the list as “Unavailable”
    // It was wrong idea. After discussing it was decided that inactive dealers must not be shown at all
    // Method was used for code prototyping and is not used anymore
    public static DealershipsAvailabilitySet(dealerships: any[], dealersStatus: GetDealerStatusResponse): void {
        dealersStatus.Dealers.forEach((dealer) => {
            if (dealer.ActiveStatus === false) {
                let nonActiveDealerId: number = dealer.ACVDealerID
                let filteredDealershipsByNonActiveDealer = dealerships.filter((dealership: any) => {
                    return dealership.id.toString() == nonActiveDealerId
                })
                filteredDealershipsByNonActiveDealer.forEach((dealership: any) => {
                    dealership.name = "Unavailable: " + dealership.name;
                    dealership.legal_name = "Unavailable: " + dealership.legal_name;
                })
            }
        })
    }


    // {
    //     "ErrorId": "",
    //     "Message": "",
    //     "ReturnCode": 0,
    //     "Dealers": [
    //       {
    //         "ACVDealerID": 3081638,
    //         "ActiveStatus": false,
    //         "SMSAgreement": true
    //       },
    //       {
    //         "ACVDealerID": 41779,
    //         "ActiveStatus": true,
    //         "SMSAgreement": true
    //       }
    //     ]
    //   }

    // JIRA SCH-255
    // when I log in to SWISS selecting the drop-down box to select the dealer,
    // any dealer that is deactivated must not be shown (in accordance with agreed rules)
    public static ActiveDealershipsGet(dealerships: any[], dealersStatus: GetDealerStatusResponse): any[] {
        // there is no information about active dealers in database
        // we do not filter the dealers
        if (dealersStatus.Dealers.length === 0) {
            return dealerships;
        }

        let activeDealersIds = dealersStatus.Dealers
            .filter(dealer => dealer.ActiveStatus === true)
            .map(dealer => dealer.ACVDealerID);
        let activeDealerships = dealerships.filter(dealership => activeDealersIds.indexOf(dealership.id) >= 0);
        return activeDealerships;
    }

    // Method returns the list of dealer ids who are not allowed to send sms
    // the list is serialized to string (to be ready to set cookie)
    public static DealersListWhoNotAllowedToSendSmsGet(dealersStatus: GetDealerStatusResponse): string {
        let ids: string[] = new Array<string>()
        dealersStatus.Dealers.forEach((dealer) => {
            if (dealer.SMSAgreement === false) {
                ids.push(dealer.ACVDealerID.toString())
            }
        })
        return Helper.SerializeToString(ids)
    }

    // Method returns the list of dealer ids who are allowed to send sms throughout the country
    // the list is serialized to string (to be ready to set cookie)
    public static DealersListWhoCanSendSmsGet(dealersStatus: GetDealerStatusResponse): string {
        let ids: string[] = new Array<string>()
        if (dealersStatus.Dealers.length > 0) {
            dealersStatus.Dealers.forEach((dealer) => {
                if (dealer.SMSAgreement) {
                    ids.push(dealer.ACVDealerID.toString());
                }
            });
        }
        return Helper.SerializeToString(ids)
    }


    public static DealersListDeserialize(dealersList: string): string[] {
        if (Helper.isEmpty(dealersList)) return new Array<string>();
        return Helper.DeserializeFromString(dealersList) as Array<string>;
    }

    public static ClearCookies(cookieService: CookieService): void {
        let cookiesNames: string[] = ['True360', 'dealerId', 'userId', 'dealerFullName', 'listOfDealersWhoCanSendSMS'];
        cookiesNames.forEach(cookieName => cookieService.delete(cookieName, '/'));
    }

    public static IsStateFlorida(stateName: string | null): boolean {
        if (Helper.isEmpty(stateName)) {
            return false;
        }
        // @ts-ignore
        let stNameUpper = stateName.toUpperCase().trim();
        return stNameUpper === "FL" || stNameUpper === "FLORIDA";
    }

    // Methods to keep VIN codes with submitted ARF in session
    static readonly keyToKeepVinCodesWithSubmittedArf: string = 'ArrayOfVinCodesWithSubmittedArf';

    private static sessionStorageWithVinsGet(): string | null {
        let str: string | null = sessionStorage.getItem(this.keyToKeepVinCodesWithSubmittedArf);
        return str;
    }

    public static AddVinCodeWithArfToSessionArray(vinCode: string): void {
        let str: string | null = this.sessionStorageWithVinsGet();
        if (str != null) {
            let restored: string[] = JSON.parse(str);
            // Keep unique VINs only
            if (restored.indexOf(vinCode) < 0) {
                restored.push(vinCode);
                sessionStorage.setItem(this.keyToKeepVinCodesWithSubmittedArf, JSON.stringify(restored));
            }
        } else {
            let restored: string[] = new Array<string>();
            restored.push(vinCode);
            sessionStorage.setItem(this.keyToKeepVinCodesWithSubmittedArf, JSON.stringify(restored));
        }
    }

    public static VinCodesArfArrayInit(){
        sessionStorage.setItem(this.keyToKeepVinCodesWithSubmittedArf, JSON.stringify([]));
    }
    public static IsVinCodeInArfArray(vinCode: string): boolean {
        let str: string | null = this.sessionStorageWithVinsGet();
        if (str != null) {
            let restored: string[] = JSON.parse(str);
            return restored.indexOf(vinCode) >= 0;

        } else {
            return false;
        }
    }
}


