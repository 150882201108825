<div class="final-dialog">
    <span style="text-align: center;">
        <h1>Thank you for your time</h1>
    </span>
    <div style="text-align: center;">
    <p>
        If you'd like to know more about the pre-qualification process, please contact the (Honda Financial Services or Acura Financial Services Lease Maturity Center) at <b>800-708-6555</b>.
    </p>
</div>
<div class="text-center mt-4">
    <button mat-raised-button class="btn btn-success btn-lg text-white fw-bold" (click)="click()" name="Close">Close</button>
  </div>
</div>