import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { SchedulerComponent } from "src/app/scheduler/scheduler.component";
import { GetSaTimeSlotsRequest } from "src/models/get-sa-time-slots-request";
import { GetWorkItemSaRequest } from "src/models/get-work-item-sa-request";
import { GetWorkItemSaResponse } from "src/models/get-work-item-sa-response";

@Component({
    selector: 'app-date-warning-dialog',
    templateUrl: './date-warning-dialog.html',
})
export class DateWarningDialogComponent {

    constructor(public dialogRef: MatDialogRef<DateWarningDialogComponent>){
        }
    
    close(): void {
        this.dialogRef.close();
    }
}

